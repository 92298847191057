export class ItcCustomSelect {
    static EL = 'itc-select';
    static EL_SHOW = 'itc-select_show';
    static EL_OPTION = 'itc-select__option';
    static EL_OPTION_SELECTED = 'itc-select__option_selected';
    static TEXT_SELECTED_EL = 'itc-select__text-selected';
    static DATA = '[data-select]';
    static DATA_TOGGLE = '[data-select="toggle"]';

    // Если будешь использовать жтот метод, то обратись к документации.
    static template(params) {
        const { name, options, disabledOptions = [], targetValue, placeholder = 'Выберите из списка', mod } = params;
        const items = [];
        let selectedIndex = -1;
        let selectedValue = '';
        // let selectedContent = 'Выберите из списка';
        let selectedContent = '';
        options.forEach((option, index) => {
            let selectedClass = '';
            if (option[0] === targetValue) {
                selectedClass = ` ${this.EL_OPTION_SELECTED}`;
                selectedIndex = index;
                selectedValue = option[0];
                selectedContent = option[1];
            }
            const disabled = disabledOptions.includes(index);
            items.push(`<li class="itc-select__option${selectedClass} ${disabled ? 'disabled' : ''}" data-select="option"
        data-value="${option[0]}" data-index="${index}">${option[1]}</li>`);
        });
        return `<input name="${name}" value="${selectedValue}"><button type="button" class="itc-select__toggle" name="${name}"
      value="${selectedValue}" data-select="toggle" data-index="${selectedIndex}">
      <div class='itc-select__text-selected'>${selectedContent}</div>
      ${mod === 'calendar' ? '<svg class="arrow-small" width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.25 0.5L4 4.25L7.75 0.5H0.25Z" fill="#49454F"/></svg>' : "<svg class='svg'><use href='/img/sprite.svg#caret-up'></use></svg>"}
      </button><div class="itc-select__placeholder">${placeholder}</div><div class="itc-select__dropdown">
      <ul class="itc-select__options">${items.join('')}</ul></div><div class='itc-select__message'></div>`;
    }

    static hideOpenSelect() {
        document.addEventListener('click', (e) => {
            if (!e.target.closest(`.${this.EL}`)) {
                const elsActive = document.querySelectorAll(`.${this.EL_SHOW}`);
                elsActive.forEach((el) => {
                    el.classList.remove(this.EL_SHOW);
                });
            }
        });
    }
    static create(target, params) {
        this._el = typeof target === 'string' ? document.querySelector(target) : target;
        if (this._el) {
            return new this(target, params);
        }
        return null;
    }
    constructor(target, params) {
        this._el = typeof target === 'string' ? document.querySelector(target) : target;
        if (!this._el) return;
        this._params = params || {};
        this._multiple = this._el.dataset.multiple || this._params.multiple ? true : false;
        this._onClickFn = this._onClick.bind(this);
        if (this._params.options) {
            this._el.innerHTML = this.constructor.template(this._params);
            this._el.classList.add(this.constructor.EL);
        }
        this._elToggle = this._el.querySelector(this.constructor.DATA_TOGGLE);
        this._input = this._el.querySelector('input');
        this._textSelectedEl = this._el.querySelector(`.${ItcCustomSelect.TEXT_SELECTED_EL}`);
        this._input.addEventListener('focus', () => this.show());
        this._input.addEventListener('blur', () => this.hide());

        // this._textSelectedEl = document.createElement('span');
        // this._textSelectedEl.classList.add('itc-select__text-selected');
        // this._elToggle.append(this._textSelectedEl);
        this._el.addEventListener('click', this._onClickFn);
    }

    _onClick(e) {
        const { target } = e;
        const type = target.closest(this.constructor.DATA)?.dataset.select;
        if (type === 'toggle') {
            this.toggle();
        } else if (type === 'option') {
            // console.log(this);
            this._changeValue(target);
        }
    }

    _updateOption(el) {
        // !!!!!!! Разобраться с индексами
        const elOption = el.closest(`.${this.constructor.EL_OPTION}`);

        if (this._multiple) {
            const updateValue = (string, cutString) => {
                // console.log(string);
                const startString = string.replace(/\|{2,}/g, '').trim();
                let fixCutString = cutString.replace(/\|{2,}/g, '').trim();
                // string.replace(/^ +| +$|( ) +/g, '$1');
                // const filter = new RegExp(`${fixCutString}(\,\\s|\,)|${fixCutString}`, 'gi');
                // {2,} - ищет вхождения от 2х до бесконечности

                let resultString = startString.replace(fixCutString, '').trim();

                resultString = resultString.replace(/^\||\|$/g, '');
                resultString = resultString.replace(/\|{2,}/g, '|');
                return resultString;
            };

            const updateText = (string, cutString) => {
                const startString = string.replace(/\s{2,}/g, '').trim();
                let fixCutString = cutString.replace(/\s{2,}/g, '').trim();
                let resultString = startString.replace(fixCutString, '').trim();
                resultString = resultString.replace(/^,|,$| ,/g, '');
                return resultString;
            };

            if (elOption.classList.contains(this.constructor.EL_OPTION_SELECTED)) {
                elOption.classList.remove(this.constructor.EL_OPTION_SELECTED);
                this._textSelectedEl.textContent = updateText(this._elToggle.textContent, elOption.textContent);
                this._elToggle.value = updateValue(this._elToggle.value, elOption.dataset.value);
                const index = updateText(this._elToggle.dataset.index, elOption.dataset.index);
                this._elToggle.dataset.index = index.length ? index : -1;
                this._input.value = this._elToggle.value;
                return;
            } else {
                this._textSelectedEl.textContent = (this._elToggle.textContent + ', ' + elOption.textContent).trim().replace(/^\,|\,$/g, '');
                // this._elToggle.textContent = (this._elToggle.textContent + ', ' + elOption.textContent).trim().replace(/^\,|\,$/g, '');
                this._elToggle.value = this._elToggle.value ? this._elToggle.value + '|' + elOption.dataset.value : elOption.dataset.value;
                this._elToggle.dataset.index = this._elToggle.dataset.index === '-1' ? elOption.dataset.index : this._elToggle.dataset.index + ', ' + elOption.dataset.index;
                this._input.value = this._elToggle.value;
            }
        } else {
            const elOptionSel = this._el.querySelector(`.${this.constructor.EL_OPTION_SELECTED}`);
            if (elOptionSel) {
                elOptionSel.classList.remove(this.constructor.EL_OPTION_SELECTED);
            }
            // this._elToggle.textContent = elOption.textContent;
            this._textSelectedEl.textContent = elOption.textContent;
            this._elToggle.value = elOption.dataset.value;
            this._elToggle.dataset.index = elOption.dataset.index;
            this._input.value = this._elToggle.value;
        }
        // if (elOptionSel) {
        //     elOptionSel.classList.remove(this.constructor.EL_OPTION_SELECTED);
        // }
        elOption.classList.add(this.constructor.EL_OPTION_SELECTED);
        // this._elToggle.textContent = elOption.textContent;
        // this._elToggle.value = elOption.dataset.value;
        // this._elToggle.dataset.index = elOption.dataset.index;
        this._el.dispatchEvent(new CustomEvent('itc.select.change'));
        this._params.onSelected ? this._params.onSelected(this, elOption) : null;
        return elOption.dataset.value;
    }

    _reset() {
        const selected = this._el.querySelector(`.${this.constructor.EL_OPTION_SELECTED}`);
        if (selected) {
            selected.classList.remove(this.constructor.EL_OPTION_SELECTED);
        }
        this._textSelectedEl.textContent = '';
        this._elToggle.value = '';
        this._input.value = '';
        this._elToggle.dataset.index = '-1';
        this._el.dispatchEvent(new CustomEvent('itc.select.change'));
        this._params.onSelected ? this._params.onSelected(this, null) : null;
        return '';
    }

    _changeValue(el) {
        // добавлено мной
        if (this._multiple) {
            this._updateOption(el);
            return;
        }
        // - - -
        if (el.classList.contains(this.constructor.EL_OPTION_SELECTED)) {
            // закрываем список при нажатии на выбранный вариант
            this.hide();
            return;
        }
        this._updateOption(el);
        this.hide();
    }

    show() {
        document.querySelectorAll(`.${this.constructor.EL_SHOW}`).forEach((el) => {
            el.classList.remove(this.constructor.EL_SHOW);
        });
        this._el.classList.add(`${this.constructor.EL_SHOW}`);
    }

    hide() {
        this._el.classList.remove(this.constructor.EL_SHOW);
    }

    toggle() {
        this._el.classList.contains(this.constructor.EL_SHOW) ? this.hide() : this.show();
    }

    dispose() {
        this._el.removeEventListener('click', this._onClickFn);
    }

    get value() {
        return this._elToggle.value;
    }

    set value(value) {
        let isExists = false;

        if (this._multiple) {
            const arrValues = value.split('|');
            const allOptions = this._el.querySelectorAll('.itc-select__option');
            allOptions.forEach((option) => {
                if (arrValues.indexOf(option.dataset.value) !== -1) {
                    isExists = true;
                    this._updateOption(option);
                }
            });
        } else {
            this._el.querySelectorAll('.itc-select__option').forEach((option) => {
                if (option.dataset.value === value) {
                    isExists = true;
                    this._updateOption(option);
                }
            });
        }

        if (!isExists) {
            this._reset();
        }
    }

    get selectedIndex() {
        return this._elToggle.dataset.index;
    }

    set selectedIndex(index) {
        const option = this._el.querySelector(`.itc-select__option[data-index="${index}"]`);
        if (option) {
            this._updateOption(option);
        } else {
            this._reset();
        }
    }
}

ItcCustomSelect.hideOpenSelect();
window.ItcCustomSelect = ItcCustomSelect;
