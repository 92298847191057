import { assignInputRules } from '../input-validate';
import { checkFilledInput } from '../validate';

function listenDateChanges() {
    const operationField = document.getElementById('date-operation');
    const observationField = document.getElementById('date-observation');

    if (operationField) {
        operationField.addEventListener('blur', setOperationDateLimit);
    }

    if (observationField) {
        observationField.addEventListener('blur', setObservationDateLimit);
    }
}

function observeGroups() {
    const operationForm = document.querySelector('.form-creating-operation__operation');
    if (operationForm) {
        function observeFn(mutations) {
            for (let mutation of mutations) {
                for (let node of mutation.addedNodes) {
                    if (!(node instanceof HTMLElement)) continue;

                    for (let elem of node.querySelectorAll('input')) {
                        if (elem.id === 'date-ballon-delete') {
                            const operationField = document.getElementById('date-operation');
                            const rules = {
                                ['date-ballon-delete']: {
                                    range: {
                                        min: 10,
                                        max: 10,
                                        message: 'Формат xx.xx.xxxx',
                                    },
                                    dateRange: {
                                        minDate: operationField.value,
                                        message: 'Не может быть раньше даты операции',
                                    },
                                },
                            };
                            assignInputRules(rules, true);
                        }
                    }
                }
            }
        }

        const observer = new MutationObserver(observeFn);
        observer.observe(operationForm, { childList: true, subtree: true });
    }
}

function setOperationDateLimit() {
    const allInputs = document.querySelectorAll('input');
    const operationField = document.getElementById('date-operation');
    const dischargeField = document.getElementById('discharge-from-hospital');
    const ballonDeleteField = document.getElementById('date-ballon-delete');
    const complicationFields = [...allInputs].filter((input) => input.id.includes('date-of-detection-complication'));

    const rules = {};

    if (dischargeField) {
        rules[dischargeField.id] = {
            required: {
                message: 'Обязательное поле',
            },
            range: {
                min: 10,
                max: 10,
                message: 'Формат xx.xx.xxxx',
            },
            dateRange: {
                minDate: operationField.value,
                message: 'Не может быть раньше даты операции',
            },
        };
    }

    if (ballonDeleteField) {
        rules[ballonDeleteField.id] = {
            range: {
                min: 10,
                max: 10,
                message: 'Формат xx.xx.xxxx',
            },
            dateRange: {
                minDate: operationField.value,
                message: 'Не может быть раньше даты операции',
            },
        };
    }

    complicationFields.forEach((input) => {
        rules[input.id] = {
            required: {
                message: 'Обязательное поле',
            },
            range: {
                min: 10,
                max: 10,
                message: 'Формат xx.xx.xxxx',
            },
            dateRange: {
                minDate: operationField.value,
                message: 'Не может быть раньше даты операции',
            },
        };
    });

    assignInputRules(rules, true);

    if (dischargeField) {
        const dischargeParentGroup = dischargeField.closest('.group');
        checkFilledInput(dischargeParentGroup);
    }
}

function setObservationDateLimit() {
    const observationField = document.getElementById('date-observation');
    const adjustingBandageField = document.getElementById('date-adjusting-bandage');

    const rules = {};

    if (adjustingBandageField) {
        rules[adjustingBandageField.name] = {
            range: {
                min: 10,
                max: 10,
                message: 'Формат xx.xx.xxxx',
            },
            dateRange: {
                minDate: observationField.value,
                message: 'Не может быть раньше даты наблюдения',
            },
        };
    }

    assignInputRules(rules, true);

    if (adjustingBandageField) {
        const adjustingBandageParentGroup = adjustingBandageField.closest('.group');
        checkFilledInput(adjustingBandageParentGroup);
    }
}

export function setDateLimit() {
    setOperationDateLimit();
    setObservationDateLimit();
    listenDateChanges();
    observeGroups();
}
