import { TooltipActions } from '../../components/tooltipActions/tooltipActions';

if (document.querySelector('.wrapper').classList.contains('patient-list')) {
    const tooltipExport = new TooltipActions({
        id: 'export-tooltip',
        options: [
            { txt: 'Экспортировать пациентов', icon: 'user', addClass: 'export-patients' },
            { txt: 'Экспортировать операции', icon: 'mask', addClass: 'export-operations' },
            { txt: 'Экспортировать наблюдения', icon: 'list', addClass: 'export-observations' },
        ],
    }).tooltip;

    const exportBtn = document.querySelector('#export-btn');
    exportBtn.addEventListener('click', showExportTooltip);
    tooltipExport.addEventListener('click', exportStart);
    function showExportTooltip() {
        const btnCoordinate = getCoords(exportBtn);
        document.body.append(tooltipExport);
        tooltipExport.style.right = 'auto';
        tooltipExport.style.left = `${btnCoordinate.left - (tooltipExport.offsetWidth - btnCoordinate.width)}px`;
        tooltipExport.style.top = `${btnCoordinate.top + (btnCoordinate.height + 10)}px`;
        tooltipExport.classList.add('is-active');
    }
}

function getCoords(elem) {
    let box = elem.getBoundingClientRect();

    return {
        top: box.top + window.scrollY,
        right: box.right + window.scrollX,
        bottom: box.bottom + window.scrollY,
        left: box.left + window.scrollX,
        height: box.height,
        width: box.width,
    };
}

async function exportStart(event) {
    const tooltip = event.target.closest('.tooltip-menu');
    const clickedElement = event.target.closest('.tooltip-menu__option');
    const tooltipItems = tooltip.querySelectorAll('.tooltip-menu__option');

    if (!clickedElement) return;

    let url = '/export/start';
    let type = '';
    let fileName = '';

    if (clickedElement.classList.contains('export-patients')) {
        type = 'patients';
        fileName = 'patients';
    } else if (clickedElement.classList.contains('export-operations')) {
        type = 'operations';
        fileName = 'operations';
    } else if (clickedElement.classList.contains('export-observations')) {
        type = 'surveys';
        fileName = 'surveys';
    }

    // отображение загрузки
    tooltipItems.forEach((item) => {
        item.classList.add('disabled');
    });
    clickedElement.classList.add('loading');
    const loader = document.createElement('div');
    loader.classList.add('loader');
    clickedElement.prepend(loader);

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            _token: document.querySelector('meta[name="csrf-token"]').content,
            type,
        }),
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
    });

    if (response.ok) {
        startCheckStatus(type, fileName);
    } else {
        clickedElement.classList.remove('loading');
        loader.remove();
        tooltipItems.forEach((item) => {
            item.classList.remove('disabled');
        });
    }
}

function initExportStatus() {
    let isPending = false;

    return function checkExportStatus(intervalId, type, fileName) {
        console.log(isPending);
        if (isPending) return;
        isPending = true;
        fetch('/export/status', {
            method: 'POST',
            body: JSON.stringify({
                _token: document.querySelector('meta[name="csrf-token"]').content,
                type,
            }),
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'finish') {
                    clearInterval(intervalId);
                    const a = document.createElement('a');
                    a.href = result.file;
                    a.setAttribute('download', fileName);
                    a.click();
                    a.remove();

                    const tooltip = document.getElementById('export-tooltip');
                    const tooltipItems = tooltip.querySelectorAll('.tooltip-menu__option');
                    tooltipItems.forEach((item) => {
                        item.classList.remove('loading');
                        item.classList.remove('disabled');
                        const loader = item.querySelector('.loader');
                        if (loader) loader.remove();
                    });
                }
                if (result.status === 'error') {
                    clearTimeout(intervalId);
                    const tooltip = document.getElementById('export-tooltip');
                    const tooltipItems = tooltip.querySelectorAll('.tooltip-menu__option');
                    tooltipItems.forEach((item) => {
                        item.classList.remove('loading');
                        item.classList.remove('disabled');
                        const loader = item.querySelector('.loader');
                        if (loader) loader.remove();
                    });
                }
            })
            .finally(() => {
                isPending = false;
            })
            .catch((error) => {
                clearTimeout(intervalId);
                console.error(error);
            });
    };
}

const checkExportStatus = initExportStatus();

function startCheckStatus(type, fileName) {
    let intervalId = setInterval(() => checkExportStatus(intervalId, type, fileName), 5000);
}

// function startInterval() {
//     // const intervalId = setInterval(() => test(intervalId), 5000);
//     const intervalId = setInterval(test, 5000, intervalId);

//     function test(x) {
//         console.log(x);
//         clearInterval(x);
//     }
// }

// startInterval();
