import { assignInputRules, setMasks } from '../../js/input-validate';
import { FileLoader } from '../../components/file-loader/file-loader';
import { setConnectionsForElements } from '../form-creating-operation/form-creating-operation';
import { hightlightRequiredFields } from '../../js/utils/create-group';
import { sendFormData } from '../../js/validate';
import { setDateLimit } from '../../js/utils/setDateLimit';

const selects = {
    'gastric-bandage': ['reason-adjusting-bandage'],
    'general-information': ['arterial-hypertension', 'diabetes-mellitus-2-type', 'compensation-SD', 'gerd', 'bronchial-asthma', 'functional-status', 'back-and-limb-pain', 'hernia', 'sagging-skin', 'fat-apron'],
    woman: ['polycystic-ovary', 'menstrual-function', 'onset-of-pregnancy'],
};

export const OBSERVATION_RULES = {
    'weight-observation': {
        required: {
            message: 'Обязательное поле',
        },
        customRange: {
            min: 60,
            max: 300,
        },
        // сюда можно добавить еще тесты
    },
    'date-observation': {
        required: {
            message: 'Обязательное поле',
        },
    },
    'date-observation': {
        required: {
            message: 'Обязательное поле',
        },
        range: {
            min: 10,
            max: 10,
            message: 'Формат xx.xx.xxxx',
        },
        dateRange: {
            minDate: '01.01.2022',
            message: 'Не может быть раньше даты операции',
        },
    },
    'date-adjusting-bandage': {
        range: {
            min: 10,
            max: 10,
            message: 'Формат xx.xx.xxxx',
        },
        dateRange: {
            minDate: '01.01.2022',
            message: 'Не может быть раньше даты операции',
        },
    },
    'changing-filling-volume': {
        required: {
            message: 'Обязательное поле',
        },
        customRange: {
            min: -5,
            max: 5,
        },
    },
    // 'polycystic-ovary': {
    //     required: {
    //         message: 'Обязательное поле',
    //     },
    // },
    // 'diabetes-mellitus-2-type': {
    //     required: {
    //         message: 'Обязательное поле',
    //     },
    // },
};

const CONNECTED = {
    'adjusting-bandage': [],
    'diabetes-сompensation': [
        {
            value: 'Нет признаков',
        },
    ],
};

function initSelects(selects) {
    const selectsList = Object.entries(selects);
    let allSelectsId = [];
    selectsList.map((item) => {
        allSelectsId = allSelectsId.concat(item[1]);
    });
    allSelectsId.forEach((selectId) => {
        new window.ItcCustomSelect(`#${selectId}`);
    });
}

document.addEventListener('DOMContentLoaded', () => {
    if (!document.querySelector('.wrapper').classList.contains('creating-observation')) return;

    const hasConnections = document.querySelectorAll('[data-has-connection]');
    hasConnections.forEach((hasConnectEl) => {
        setConnectionsForElements(hasConnectEl, CONNECTED);
    });

    const loader = new FileLoader({
        type: 'dropzone',
        name: 'create-observation-files',
        target: '#create-observation-files',
    });

    new window.Calendar('#date-observation');
    new window.Calendar('#date-adjusting-bandage');
    setDateLimit();

    // БАНДАЖИРОВАНИЕ ЖЕЛКУДКА. Логика заполнения группы(is-filled)
    // const groupGastricBandaging = document.querySelector('#group-gastric-banding');
    // if (groupGastricBandaging) {
    //     const checkboxAdjasting = groupGastricBandaging.querySelector('input[name="adjusting-bandage"]');
    //     const inputVolume = groupGastricBandaging.querySelector('input[name="changing-filling-volume"]');

    //     checkboxAdjasting.addEventListener('change', (e) => {
    //         if (e.target.checked) {
    //             groupGastricBandaging.classList.remove('ignore-filled');
    //             if (inputVolume.value) {
    //                 groupGastricBandaging.classList.add('is-filled');
    //             }
    //         } else {
    //             groupGastricBandaging.classList.add('ignore-filled');
    //             groupGastricBandaging.classList.remove('is-filled');
    //         }
    //     });
    // }

    const FORM = document.querySelector('form');
    if (FORM) {
        FORM.addEventListener('submit', (e) => {
            e.preventDefault();
            sendFormData(FORM, loader);
        });
    }

    setMasks();
    initSelects(selects);
    hightlightRequiredFields();
    assignInputRules(OBSERVATION_RULES);
});
