import IMask from 'imask';

const stopChangeValueByScrolling = (event) => {
    event.preventDefault();
};

const numberInputs = document.querySelectorAll('input[type="number"]');
numberInputs.forEach((input) => input.addEventListener('wheel', stopChangeValueByScrolling));

function checkNewInputs(mutations) {
    for (let mutation of mutations) {
        for (let node of mutation.addedNodes) {
            if (!(node instanceof HTMLElement)) continue;

            if (node.matches('input[type="number"]')) {
                node.addEventListener('wheel', stopChangeValueByScrolling);
            }

            for (let elem of node.querySelectorAll('input[type="number"]')) {
                elem.addEventListener('wheel', stopChangeValueByScrolling);
            }
        }
    }
}

const allGroups = document.querySelectorAll('.group');
allGroups.forEach((group) => {
    const numberInputsObserver = new MutationObserver(checkNewInputs);
    numberInputsObserver.observe(group, { childList: true, subtree: true });
});

let approve = require('approvejs');
/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
// customRange
let customRange = {
    expects: ['min', 'max'],
    message: 'Диапазон значений: от {min} до {max}',
    validate: function (value, params) {
        let result = {
            valid: true,
            errors: [],
        };

        if (value >= params.min && value <= params.max) {
            result.valid = true;
        } else {
            result.valid = false;
        }

        return result;
    },
};

let minValue = {
    expects: ['min'],
    message: 'Не может быть меньше {min}',
    validate: function (value, params) {
        let result = {
            valid: true,
            errors: [],
        };

        if (value < params.min) {
            result.valid = false;
        }

        return result;
    },
};

let dateRange = {
    expects: ['minDate'],
    message: 'Неверная дата',
    validate: function (value, params) {
        let result = {
            valid: true,
            errors: [],
        };

        if (value.length < 10 || !params.minDate) return result;

        const minYear = params.minDate.slice(6, 10);
        const minMonth = params.minDate.slice(3, 5);
        const minDay = params.minDate.slice(0, 2);
        const minDate = new Date(`${minYear}-${minMonth}-${minDay}`);

        const valueDateYear = value.slice(6, 10);
        const valueDateMonth = value.slice(3, 5);
        const valueDateDay = value.slice(0, 2);
        const valueDate = new Date(`${valueDateYear}-${valueDateMonth}-${valueDateDay}`);

        if (minDate <= valueDate) {
            // console.log('Корректная дата');
            result.valid = true;
        } else {
            // console.log('Слишком рано');
            result.valid = false;
        }

        return result;
    },
};

approve.addTest(customRange, 'customRange');
approve.addTest(dateRange, 'dateRange');
approve.addTest(minValue, 'minValue');

export function checkValidate(element, rules) {
    let message;
    let result;

    // input
    const inputWrapper = element.closest('.input-custom');
    const textarea = element.closest('.textarea');
    if (inputWrapper) {
        message = inputWrapper.querySelector('.input-custom__message');
        result = approve.value(element.value, rules);
    }
    // textarea
    else if (textarea) {
        message = textarea.querySelector('.textarea__message');
        result = approve.value(element.value, rules);
    }
    // select
    else {
        const button = element.querySelector('button');
        message = element.querySelector('.itc-select__message');
        result = approve.value(button.value, rules);
    }

    if (!result.approved) {
        message.innerText = result.errors[0] || '';
        message.classList.add('error-message');
        element.classList.add('is-invalid');
    } else {
        element.classList.remove('is-invalid');
        message.classList.remove('error-message');
    }
}

// fixme
// assignInputRules - при изменении правил в realtime, они наслаиваются друг на друга
// и функция checkValidate - отрабатывает много раз на одном элементе.

export function assignInputRules(allRules, checkRightNow) {
    const AllElements = document.querySelectorAll('.input-custom input, .itc-select, .textarea textarea');
    const rules = Object.keys(allRules);
    AllElements.forEach((element) => {
        if (rules.indexOf(element.id) !== -1) {
            // console.log(element.id)
            let currentElement;
            if (element.closest('.itc-select')) {
                currentElement = element.closest('.itc-select');
                const selectButton = element.querySelector('button');
                // const selectItems = select.querySelectorAll('.itc-select__option');
                selectButton.addEventListener('click', () => checkValidate(currentElement, allRules[element.id]));
                // selectItems.forEach((item) => item.addEventListener('click', () => checkValidate(select, allRules[element.id])));
                const observer = new MutationObserver(() => checkValidate(currentElement, allRules[currentElement.id]));
                observer.observe(selectButton, { attributes: true, attributeFilter: ['value'] });
            } else {
                currentElement = element;
                currentElement.addEventListener('input', () => checkValidate(currentElement, allRules[element.id]));
                // currentElement.addEventListener('change', () => checkValidate(currentElement, allRules[element.id]));
                currentElement.addEventListener('blur', () => {
                    checkValidate(currentElement, allRules[element.id]);
                    if (!currentElement.value && !currentElement.hasAttribute('data-required')) {
                        currentElement.classList.remove('is-invalid');
                    }
                });
            }

            if (checkRightNow) {
                let inputInner;
                if (currentElement.closest('.itc-select')) {
                    inputInner = currentElement.querySelector('input');
                } else {
                    inputInner = currentElement;
                }
                if (inputInner.value) {
                    checkValidate(currentElement, allRules[element.id]);
                }
            }
        }
    });
}

export function setMasks(input) {
    // masks
    const maskRules = {
        phone: {
            mask: '+{7}(#00)000-00-00',
            definitions: {
                '#': /9/,
            },
        },
        date: {
            mask: Date,
            // min: new Date(1990, 0, 1),
            // max: new Date(2020, 0, 1),
            // lazy: false,
        },
    };

    if (input) {
        IMask(input, maskRules[input.dataset.mask]);
        return;
    }

    const elements = document.querySelectorAll('[data-mask]');
    if (elements) {
        elements.forEach((el) => {
            IMask(el, maskRules[el.dataset.mask]);
        });
    }
}

export function inputTrigger(input) {
    input.focus();
    input.blur();
}
