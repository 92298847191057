import { OPERATIONS, REVISION_OPERATIONS } from '../../js/operation-data';

export const complicationKeys = ['date-of-detection-complication', 'main-complication', 'scale-complication', 'localization-complication', 'type-of-complication', 'by-Clavien-Dindo-complication', 'note-complication'];

export const interventionKeys = ['date-repeated-operation', 'hours-from-main-operation', 'method-repeated-operation', 'type-repeated-operation', 'note-intervention'];

export const complAndInterKeys = [...complicationKeys, ...interventionKeys];

const templateOperation = {
    id: 1,
    name: 'Имя операции',
    date: '(22.02.2015)',
    files: [],
    operation: {
        info: [
            // Общие сведения
            {
                id: 'general-information',
                name: 'Общие сведения',
                addClass: ['group--simple'],
                dataBlocks: [
                    {
                        name: 'weight-operation',
                        title: 'Вес на момент операции',
                    },
                    {
                        name: 'date-operation',
                        title: 'Дата операции',
                    },
                    {
                        name: 'surgeon',
                        title: 'Хирург',
                    },
                    {
                        name: 'choosing-clinic',
                        title: 'Клиника',
                    },
                    {
                        name: 'assistants',
                        title: 'Ассистенты',
                    },
                    {
                        name: 'type-of-operation',
                        title: 'Тип операции',
                    },
                    {
                        name: 'reason-for-revision',
                        title: 'Причина ревизии',
                    },
                    {
                        name: 'type-of-complication',
                        title: 'Тип осложнения',
                    },
                    {
                        name: 'type-of-revision-operation',
                        title: 'Вид ревизионной операции',
                    },
                    {
                        name: 'access',
                        title: 'Доступ',
                    },
                    {
                        name: 'duration-operation',
                        title: 'Длительность операции (мин)',
                    },
                    {
                        name: 'pain-relief',
                        title: 'Обезболивание',
                    },
                    {
                        name: 'simultaneous-operation',
                        title: 'Симультанная операция',
                    },
                ],
            },
            // Интраоперационные осложнения
            {
                id: 'group-intraoperative-complications',
                name: 'Интраоперационные осложнения',
                addClass: ['group--simple', 'group--parent'],
                subtitle: 'Трудности и отклонения от стандартного хода операции, которые возникли в операционной и привели кизменению тактики, увеличению длительности или прекращению вмешательства.',
                additionalGroups: [
                    {
                        name: 'Интраоперационные осложнения',
                        addClass: 'group--simple',
                        active: true,
                        dataBlocks: [
                            {
                                name: 'bleeding',
                                title: 'Кровотечение',
                            },
                            {
                                name: 'positive-leak-test',
                                title: 'Позитивный тест на герметичность',
                            },
                            {
                                name: 'injury-of-organs',
                                title: 'Перфорация и ранение органов',
                            },
                            {
                                name: 'electrotrauma-of-organs',
                                title: 'Электротравма органов',
                            },
                            {
                                name: 'intraoperative-complications-comment',
                                title: 'Другое/комментарии',
                            },
                        ],
                    },
                    {
                        name: 'Нештатные ситуации',
                        addClass: 'group--simple',
                        active: true,
                        dataBlocks: [
                            // Сюда нужно добавить все нештатные ситуации
                            {
                                name: 'emergency-situation1',
                                title: 'Заклинивание степлера',
                            },
                            {
                                name: 'emergency-situation2',
                                title: 'Неправильное срабатывание кассеты',
                            },
                            {
                                name: 'emergency-situation3',
                                title: 'Трудности установки зонда',
                            },
                            {
                                name: 'emergency-situation4',
                                title: 'Прошивание зонда степлером',
                            },
                            {
                                name: 'emergency-situation5',
                                title: 'Невозможность экспозиции печени',
                            },
                            {
                                name: 'emergency-situation6',
                                title: 'Выраженный спаечный процесс',
                            },
                            {
                                name: 'emergency-situation7',
                                title: 'Анестезиологические осложнения',
                            },
                            {
                                name: 'emergency-situation8',
                                title: 'Обнаружено новообразование',
                            },
                            {
                                name: 'emergency-situation9',
                                title: 'Поломка элементов оборудования',
                            },
                            {
                                name: 'emergency-situation10',
                                title: 'Ухудшение состояния хирурга/ассистента',
                            },
                            {
                                name: 'emergency-situation-comment',
                                title: 'Другое/комментарии',
                            },
                        ],
                    },
                ],
            },
            // Пребывание в стационаре
            {
                id: 'hospital-stay',
                name: 'Пребывание в стационаре',
                addClass: ['group--simple', 'group--parent'],
                dataBlocks: [
                    // Добавить name
                    {
                        name: 'vomiting',
                        title: 'Наличие рвоты после операции',
                    },
                    {
                        name: 'discharge-from-hospital',
                        title: 'Дата выписки',
                    },
                    {
                        name: 'discharge-where',
                        title: 'Выписка',
                    },
                    {
                        name: 'cause-of-death',
                        title: 'Причина летального исхода',
                    },
                    {
                        name: 'electrolyte-disturbances',
                        title: 'Электролитные нарушения',
                    },
                    {
                        name: 'rhabdomyolysis',
                        title: 'Рабдомиолиз',
                    },
                ],
            },
            // Дополнительная информация
            {
                id: 'additional-information',
                name: 'Дополнительная информация',
                addClass: ['group--simple'],
                dataBlocks: [
                    {
                        name: 'operation-additional',
                        title: 'Примечания',
                    },
                ],
            },
            // Ранние Осложнения
            // {
            //     // groupName: 'complication',
            //     name: 'Раннее осложнение №1',
            //     addClass: ['group--simple'],
            //     dataBlocks: [
            //         {
            //             name: '',
            //             title: 'Дата выявления осложнения',
            //             value: '16.12.2020',
            //         },
            //         {
            //             name: '',
            //             title: 'Основное осложнение',
            //             value: 'Кровотечение',
            //         },
            //         {
            //             name: '',
            //             title: 'Масштаб',
            //             value: 'Внутрипросветное',
            //         },
            //         {
            //             name: '',
            //             title: 'Локализация',
            //             value: 'Из гастроэнтероанастомоза',
            //         },
            //         {
            //             name: '',
            //             title: 'Тяжесть осложнения по Clavien-Dindo',
            //             value: 'IVb - интенсивная терапия (полиорганная недостаточность)',
            //         },
            //     ],
            // },
        ],
        fields: [
            // Общие сведения
            {
                id: 'general-information',
                title: 'Общие сведения',
                addClass: ['group--simple'],
                fields: [
                    {
                        type: 'INPUT',
                        data: {
                            name: 'weight-operation',
                            type: 'text',
                            placeholder: 'Вес на момент операции*',
                            required: true,
                            addClass: ['only-number'],
                        },
                    },
                    {
                        type: 'INPUT',
                        data: {
                            name: 'date-operation',
                            type: 'text',
                            placeholder: 'Дата операции*',
                            required: true,
                            mod: 'calendar',
                            addClass: ['only-number', 'input-custom--violet'],
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'surgeon',
                            placeholder: 'Хирург',
                            options: window.SURGEONS || [
                                ['List item 1', 'List item 1'],
                                ['List item 1', 'List item 1'],
                                ['List item 1', 'List item 1'],
                                ['List item 1', 'List item 1'],
                            ],
                            required: true,
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'assistants',
                            placeholder: 'Ассистенты',
                            options: window.ASSISTANTS || [
                                ['Викториан Виктор Викторович', 'Викториан Виктор Викторович'],
                                ['Тестовый Тест Тестович', 'Тестовый Тест Тестович'],
                                ['Ассистентый Ассистент', 'Ассистентый Ассистент'],
                                ['Бобиков Бобик Бобикович', 'Бобиков Бобик Бобикович'],
                            ],
                            multiple: true,
                            required: false,
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'choosing-clinic',
                            placeholder: 'Выбор клиники',
                            options: window.CLINICS || [
                                ['List item 1', 'List item 1'],
                                ['List item 1', 'List item 1'],
                                ['List item 1', 'List item 1'],
                                ['List item 1', 'List item 1'],
                            ],
                            required: false,
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'type-of-operation',
                            placeholder: 'Тип операции*',
                            options: [
                                ['Первичная', 'Первичная'],
                                ['Ревизионная (по поводу неэффективности или осложнений предыдущей операции)', 'Ревизионная (по поводу неэффективности или осложнений предыдущей операции)'],
                                ['Плановый второй этап', 'Плановый второй этап'],
                                ['Ревизия после бариатрической операции в другой клинике', 'Ревизия после бариатрической операции в другой клинике'],
                            ],
                            required: true,
                            hasConnection: 'operation-type',
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'reason-for-revision',
                            placeholder: 'Причина ревизии',
                            options: [
                                ['Рецидив набора веса', 'Рецидив набора веса'],
                                ['Недостаточное снижение веса', 'Недостаточное снижение веса'],
                                ['Осложнение', 'Осложнение'],
                                ['Удаление БЖ/ВЖБ', 'Удаление БЖ/ВЖБ'],
                                ['Эстетическая, косметологическая, иная причина', 'Эстетическая, косметологическая, иная причина'],
                            ],
                            required: true,
                            connected: 'operation-type',
                            connectedID: 'revision',
                            hasConnection: 'reason-revision',
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'type-of-complication',
                            placeholder: 'Тип осложнения',
                            options: [
                                ['Последствия несостоятельности', 'Последствия несостоятельности'],
                                ['Непроходимость и стеноз', 'Непроходимость и стеноз'],
                                ['Язва', 'Язва'],
                                ['Нутриентная недостаточность', 'Нутриентная недостаточность'],
                                ['Иная грыжа', 'Иная грыжа'],
                                ['Другое', 'Другое'],
                            ],
                            required: true,
                            connected: 'reason-revision',
                            connectedID: 'complication',
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'type-of-revision-operation',
                            placeholder: 'Вид ревизионной операции',
                            options: [
                                ['Иная ревизионная операция', 'Иная ревизионная операция'],
                                ['Конверсия в др. бариатрическую операцию', 'Конверсия в др. бариатрическую операцию'],
                            ],
                            required: true,
                            connected: 'operation-type',
                            connectedID: 'revision',
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'access',
                            placeholder: 'Доступ',
                            options: [
                                ['Лапароскопия', 'Лапароскопия'],
                                ['Лапаротомия', 'Лапаротомия'],
                                ['Эндоскопия', 'Эндоскопия'],
                                ['Конверсия из лапароскопии в лапаротомию', 'Конверсия из лапароскопии в лапаротомию'],
                                ['Конверсия из лапароскопии в мануально ассистированную', 'Конверсия из лапароскопии в мануально ассистированную'],
                                ['SILS', 'SILS'],
                                ['Конверсия SILS в традиционную лапароскопию', 'Конверсия SILS в традиционную лапароскопию'],
                            ],

                            required: true,
                            hasConnection: 'access',
                        },
                    },
                    {
                        type: 'INPUT',
                        data: {
                            name: 'duration-operation',
                            type: 'number',
                            placeholder: 'Длительность операции (мин)',
                            required: false,
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'pain-relief',
                            placeholder: 'Обезболивание',
                            options: [
                                ['в/в', 'в/в'],
                                ['ЭТН', 'ЭТН'],
                                ['ЭТН + перидуральная анестезия', 'ЭТН + перидуральная анестезия'],
                                ['ЭТН + пластырь', 'ЭТН + пластырь'],
                            ],

                            required: false,
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            name: 'simultaneous-operation',
                            placeholder: 'Симультанная операция',
                            options: [
                                ['Холецистэктомия', 'Холецистэктомия'],
                                ['Герниопластика', 'Герниопластика'],
                                ['Аппендэктомия', 'Аппендэктомия'],
                                ['Гинекологическая операция', 'Гинекологическая операция'],
                                ['Круроррафия', 'Круроррафия'],
                                ['Другое', 'Другое'],
                            ],

                            required: false,
                        },
                    },
                ],
            },
            // Интраоперационные осложнения
            {
                id: 'group-intraoperative-complications',
                title: 'Интраоперационные осложнения',
                addClass: ['group--simple', 'group--parent', 'ignore-filled'],
                subtitle: 'Трудности и отклонения от стандартного хода операции, которые возникли в операционной и привели к изменению тактики, увеличению длительности или прекращению вмешательства.',
                additionalGroups: [
                    {
                        name: 'Вид осложнения',
                        active: true,
                        id: 'type-complication',
                        addClass: ['group--simple', 'ignore-filled'],
                        content: [
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'intraoperative-complications',
                                    value: 'Да',
                                    placeholder: 'Интраоперационные осложнения',
                                    hasConnection: 'intraoperative-complications',
                                    addClass: 'is-active',
                                    connected: 'no-complications',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situations',
                                    value: 'Да',
                                    placeholder: 'Нештатные ситуации',
                                    hasConnection: 'emergency-situations',
                                    addClass: 'is-active',
                                    connected: 'no-complications',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'no-complications',
                                    value: 'Да',
                                    placeholder: 'Нет осложнений',
                                    hasConnection: 'no-complications',
                                },
                            },
                        ],
                    },
                    {
                        name: 'Интраоперационные осложнения',
                        active: false,
                        addClass: ['group--simple'],
                        connected: 'intraoperative-complications',
                        content: [
                            {
                                type: 'SELECT',
                                data: {
                                    name: 'bleeding',
                                    placeholder: 'Кровотечение',
                                    options: [
                                        ['Из троакарной раны', 'Из троакарной раны'],
                                        ['Из артерии/вены', 'Из артерии/вены'],
                                        ['Другое', 'Другое'],
                                    ],
                                },
                            },
                            {
                                type: 'SELECT',
                                data: {
                                    name: 'positive-leak-test',
                                    placeholder: 'Позитивный тест на герметичность',
                                    options: [
                                        ['Степлерной линии', 'Степлерной линии'],
                                        ['Анастомоза', 'Анастомоза'],
                                    ],
                                },
                            },
                            {
                                type: 'SELECT',
                                data: {
                                    name: 'injury-of-organs',
                                    placeholder: 'Перфорация и ранение органов',
                                    options: [
                                        ['Тонкой кишки', 'Тонкой кишки'],
                                        ['12-перстной кишки', '12-перстной кишки'],
                                        ['Ободочной кишки', 'Ободочной кишки'],
                                        ['Желудка', 'Желудка'],
                                        ['Пищевода', 'Пищевода'],
                                        ['Диафрагмы', 'Диафрагмы'],
                                        ['Печени', 'Печени'],
                                        ['Селезенки', 'Селезенки'],
                                        ['Поджелудочной железы', 'Поджелудочной железы'],
                                        ['Другое', 'Другое'],
                                    ],
                                },
                            },
                            {
                                type: 'SELECT',
                                data: {
                                    name: 'electrotrauma-of-organs',
                                    placeholder: 'Электротравма органов',
                                    options: [
                                        ['Тонкой кишки', 'Тонкой кишки'],
                                        ['12-перстной кишки', '12-перстной кишки'],
                                        ['Ободочной кишки', 'Ободочной кишки'],
                                        ['Желудка', 'Желудка'],
                                        ['Пищевода', 'Пищевода'],
                                        ['Диафрагмы', 'Диафрагмы'],
                                        ['Печени', 'Печени'],
                                        ['Селезенки', 'Селезенки'],
                                        ['Поджелудочной железы', 'Поджелудочной железы'],
                                        ['Другое', 'Другое'],
                                    ],
                                },
                            },
                            {
                                type: 'INPUT',
                                data: {
                                    id: 'intraoperative-complications-comment',
                                    name: 'intraoperative-complications-comment',
                                    type: 'text',
                                    placeholder: 'Другое/комментарии',
                                    addClass: ['long', 'input-custom--violet'],
                                },
                            },
                        ],
                    },
                    {
                        name: 'Нештатные ситуации',
                        active: false,
                        addClass: ['group--simple'],
                        connected: 'emergency-situations',
                        content: [
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation1',
                                    value: 'Да',
                                    placeholder: 'Заклинивание степлера',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation2',
                                    value: 'Да',
                                    placeholder: 'Неправильное срабатывание кассеты',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation3',
                                    value: 'Да',
                                    placeholder: 'Трудности установки зонда',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation4',
                                    value: 'Да',
                                    placeholder: 'Прошивание зонда степлером',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation5',
                                    value: 'Да',
                                    placeholder: 'Невозможность экспозиции печени',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation6',
                                    value: 'Да',
                                    placeholder: 'Выраженный спаечный процесс',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation7',
                                    value: 'Да',
                                    placeholder: 'Анестезиологические осложнения',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation8',
                                    value: 'Да',
                                    placeholder: 'Обнаружено новообразование',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation9',
                                    value: 'Да',
                                    placeholder: 'Ухудшение состояния хирурга/ассистента',
                                },
                            },
                            {
                                type: 'CHECKBOX',
                                data: {
                                    name: 'emergency-situation10',
                                    value: 'Да',
                                    placeholder: 'Поломка элементов оборудования',
                                },
                            },
                            {
                                type: 'INPUT',
                                data: {
                                    id: 'emergency-situation-comment',
                                    name: 'emergency-situation-comment',
                                    type: 'text',
                                    placeholder: 'Другое/комментарии',
                                    addClass: ['long', 'input-custom--violet'],
                                },
                            },
                        ],
                    },
                ],
            },
            // Пребывание в стационаре
            {
                id: 'hospital-stay',
                title: 'Пребывание в стационаре и исход',
                addClass: ['group--simple'],
                fields: [
                    {
                        type: 'SELECT',
                        data: {
                            id: 'vomiting',
                            name: 'vomiting',
                            placeholder: 'Наличие рвоты',
                            options: [
                                ['Кратковременная, прошла самостоятельно', 'Кратковременная, прошла самостоятельно'],
                                ['Кратковременная, купированна препаратами', 'Кратковременная, купированна препаратами'],
                                ['Упорная рвота', 'Упорная рвота'],
                            ],
                        },
                    },
                    {
                        type: 'INPUT',
                        data: {
                            id: 'discharge-from-hospital',
                            name: 'discharge-from-hospital',
                            type: 'text',
                            placeholder: 'Дата выписки',
                            addClass: ['long', 'input-custom--violet'],
                            mod: 'calendar',
                            addClass: ['only-number', 'input-custom--violet'],
                            required: true,
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            id: 'discharge-where',
                            name: 'discharge-where',
                            placeholder: 'Выписка',
                            options: [
                                ['В другой госпиталь', 'В другой госпиталь'],
                                ['Домой', 'Домой'],
                                ['Летальный исход', 'Летальный исход'],
                            ],
                            hasConnection: 'fatal-outcome',
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            id: 'cause-of-death',
                            name: 'cause-of-death',
                            placeholder: 'Причина летального исхода',
                            options: [
                                ['ТЭЛА', 'ТЭЛА'],
                                ['Сердечно-сосудистая недостаточность', 'Сердечно-сосудистая недостаточность'],
                                ['Несостоятельность', 'Несостоятельность'],
                                ['Кровотечение', 'Кровотечение'],
                                ['Инфекционно-токсический шок', 'Инфекционно-токсический шок'],
                                ['Пневмония', 'Пневмония'],
                                ['Другая причина', 'Другая причина'],
                                ['Другая причина', 'Другая причина'],
                            ],
                            connected: 'fatal-outcome',
                        },
                    },
                    {
                        type: 'CHECKBOX',
                        data: {
                            name: 'electrolyte-disturbances',
                            value: 'Да',
                            placeholder: 'Электролитные нарушения',
                        },
                    },
                    {
                        type: 'CHECKBOX',
                        data: {
                            name: 'rhabdomyolysis',
                            value: 'Да',
                            placeholder: 'Рабдомиолиз',
                        },
                    },
                ],
            },
            // Дополнительная информация
            {
                id: 'additional-information',
                title: 'Дополнительная информация',
                addClass: ['group--simple', 'group--parent'],
                fields: [
                    {
                        type: 'TEXTAREA',
                        data: {
                            id: 'operation-additional',
                            name: 'operation-additional',
                            type: 'text',
                            placeholder: 'Дополнительная информация',
                            required: false,
                            addClass: ['long', 'input-custom--violet'],
                        },
                    },
                ],
            },
        ],
    },
    observations: [],
};

const templateObservation = {
    txt: 'Тест мес.',
    id: 1,
    files: [
        { name: '', link: '', type: '', img: '' },
        { name: '', link: '', type: '', img: '' },
    ],
    info: [
        {
            name: 'Сведения о пациенте',
            addClass: ['group--simple', 'three-columns'],
            dataBlocks: [
                {
                    name: 'date-observation',
                    title: 'Дата наблюдения',
                    value: '',
                },
                {
                    name: 'weight-observation',
                    title: 'Вес на момент наблюдения',
                    value: '',
                },
                {
                    name: 'EWL',
                    title: '%EWL',
                    value: '',
                },
            ],
        },
        {
            name: 'Бандажирование желудка',
            addClass: ['group--simple', 'three-columns'],
            dataBlocks: [
                {
                    name: 'reason-adjusting-bandage',
                    title: 'Причина регулировки',
                    value: '',
                },
                {
                    name: 'changing-filling-volume',
                    title: 'Изменение объема заполнения',
                    value: '',
                },
                {
                    name: 'date-adjusting-bandage',
                    title: 'Планируемая дата регулировки',
                    value: '',
                },
            ],
        },
        {
            name: 'Общие сведения',
            addClass: ['group--simple'],
            dataBlocks: [
                {
                    name: 'feeling-hungry',
                    title: 'Чувство голода',
                    value: '',
                },
                {
                    name: 'intake-of-vitamins',
                    title: 'Приём витаминов и микроэлементов',
                    value: '',
                },
                {
                    name: 'signs-of-nutritional-deficiency',
                    title: 'Признаки питательной недостаточности',
                    value: '',
                },
                {
                    name: 'comfort-of-nutrition',
                    title: 'Комфортность питания',
                    value: '',
                },
                {
                    name: 'diarrhea',
                    title: 'Диарея',
                    value: '',
                },
                {
                    name: 'has-constipation',
                    title: 'Наличие запора',
                    value: '',
                },
                {
                    name: 'how-often-poop',
                    title: 'Частота стула',
                    value: '',
                },
            ],
        },
        {
            name: 'Течение сопутствующих заболеваний',
            addClass: ['group--simple'],
            dataBlocks: [
                {
                    name: 'arterial-hypertension',
                    title: 'Артериальная гипертензия',
                    value: '',
                },
                {
                    name: 'diabetes-mellitus-2-type',
                    title: 'Сахарный диабет 2-го типа',
                    value: '',
                },
                {
                    name: 'compensation-SD',
                    title: 'Срок наступления компенсации СД',
                    value: '',
                },
                {
                    name: 'gerd',
                    title: 'ГЭРБ',
                    value: '',
                },
                {
                    name: 'dyslipidemia',
                    title: 'Дислипидемия',
                    value: '',
                },
                {
                    name: 'atherosclerosis',
                    title: 'Атеросклероз',
                    value: '',
                },
                {
                    name: 'apnea',
                    title: 'Апноэ',
                    value: '',
                },
                {
                    name: 'bronchial-asthma',
                    title: 'Бронхиальная астма',
                    value: '',
                },
                {
                    name: 'functional-status',
                    title: 'Функциональный статус',
                    value: '',
                },
                {
                    name: 'back-and-limb-pain',
                    title: 'Боль в спине и конечностях',
                    value: '',
                },
                {
                    name: 'hernia',
                    title: 'Грыжа',
                    value: '',
                },
                {
                    name: 'sagging-skin',
                    title: 'Обвисание кожи',
                    value: '',
                },
                {
                    name: 'fat-apron',
                    title: 'Жировой фартук',
                    value: '',
                },
                {
                    name: 'urinary-incontinence',
                    title: 'Недержание мочи',
                    value: '',
                },
                {
                    name: 'depression',
                    title: 'Депрессия',
                    value: '',
                },
                {
                    name: 'cholelithiasis',
                    title: 'Желчнокаменная болезнь',
                    value: '',
                },
            ],
            additionalGroups: [
                {
                    name: 'Женское здоровье',
                    addClass: 'group--simple',
                    active: true,
                    dataBlocks: [
                        {
                            name: 'polycystic-ovary',
                            title: 'Синдром поликистозных яичников',
                            value: '',
                        },
                        {
                            name: 'menstrual-function',
                            title: 'Менструальная функция',
                            value: '',
                        },
                        {
                            name: 'onset-of-pregnancy',
                            title: 'Наступление беременности',
                            value: '',
                        },
                    ],
                },
            ],
        },
        {
            name: 'Дополнительная информация',
            addClass: ['group--simple'],
            dataBlocks: [
                {
                    name: 'observation-additional',
                    title: 'Примечания',
                    value: '',
                },
            ],
        },
    ],
    fields: [
        // Сведения о пациенте
        {
            id: 'patient-information',
            title: 'Сведения о пациенте',
            addClass: ['group--simple', 'group--parent'],
            fields: [
                {
                    type: 'INPUT',
                    data: {
                        name: 'date-observation',
                        type: 'text',
                        placeholder: 'Дата наблюдения',
                        required: true,
                        mod: 'calendar',
                        addClass: ['only-number', 'input-custom--violet'],
                    },
                },
                {
                    type: 'INPUT',
                    data: {
                        name: 'weight-observation',
                        type: 'number',
                        placeholder: 'Вес на момент наблюдения',
                        required: true,
                    },
                },
                {
                    type: 'INPUT',
                    data: {
                        name: 'EWL',
                        type: 'number',
                        placeholder: '%EWL',
                        required: false,
                        disabled: true,
                    },
                },
            ],
        },
        // Бандажирование желудка
        {
            id: 'gastric-banding',
            title: 'Бандажирование желудка',
            addClass: ['group--simple', 'group--parent'],
            fields: [
                {
                    type: 'SELECT',
                    data: {
                        name: 'reason-adjusting-bandage',
                        placeholder: 'Причина регулировки',
                        options: [
                            ['Первичная регулировка после операции', 'Первичная регулировка после операции'],
                            ['Дисфагия', 'Дисфагия'],
                            ['Повторный набор веса', 'Повторный набор веса'],
                            ['Беременность', 'Беременность'],
                            ['Неудовлетворенность пациента', 'Неудовлетворенность пациента'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'INPUT',
                    data: {
                        name: 'changing-filling-volume',
                        type: 'number',
                        placeholder: 'Изменение объема заполнения',
                        required: true,
                        addClass: ['only-number'],
                    },
                },
                {
                    type: 'INPUT',
                    data: {
                        id: 'date-adjusting-bandage',
                        name: 'date-adjusting-bandage',
                        type: 'text',
                        placeholder: 'Планируемая дата регулировки',
                        required: true,
                        mod: 'calendar',
                        addClass: ['only-number', 'input-custom--violet'],
                    },
                },
            ],
        },
        // Общие сведения
        {
            id: 'general-information',
            title: 'Общие сведения',
            addClass: ['group--simple', 'group--parent'],
            fields: [
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'feeling-hungry',
                        name: 'feeling-hungry',
                        placeholder: 'Чувство голода',
                        options: [
                            ['Сниженое', 'Сниженое'],
                            ['Обычное', 'Обычное'],
                            ['Выраженное', 'Выраженное'],
                        ],
                        addClass: 'long',
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'intake-of-vitamins',
                        name: 'intake-of-vitamins',
                        placeholder: 'Приём витаминов и микроэлементов',
                        options: [
                            ['Да', 'Да'],
                            ['Нет', 'Нет'],
                        ],
                        required: true,
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'signs-of-nutritional-deficiency',
                        name: 'signs-of-nutritional-deficiency',
                        placeholder: 'Признаки питательной недостаточности',
                        options: [
                            ['Есть', 'Есть'],
                            ['Нет', 'Нет'],
                        ],
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'comfort-of-nutrition',
                        name: 'comfort-of-nutrition',
                        placeholder: 'Комфортность питания',
                        options: [
                            ['Нормальная', 'Нормальная'],
                            ['Дискомфорт при приеме пищи', 'Дискомфорт при приеме пищи'],
                        ],
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'diarrhea',
                        name: 'diarrhea',
                        placeholder: 'Диарея',
                        options: [
                            ['Да', 'Да'],
                            ['Нет', 'Нет'],
                        ],
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'has-constipation',
                        name: 'has-constipation',
                        placeholder: 'Наличие запора',
                        options: [
                            ['Да', 'Да'],
                            ['Нет или очень редко', 'Нет или очень редко'],
                        ],
                    },
                },
                {
                    type: 'INPUT',
                    data: {
                        name: 'how-often-poop',
                        type: 'text',
                        placeholder: 'Частота стула',
                        required: false,
                    },
                },
            ],
        },
        // Течение сопутствующих заболеваний
        {
            id: 'concomitant diseases',
            title: 'Течения сопутствующих заболеваний',
            addClass: ['group--simple', 'group--parent'],
            fields: [
                {
                    type: 'SELECT',
                    data: {
                        id: 'arterial-hypertension',
                        name: 'arterial-hypertension',
                        placeholder: 'Артериальная гипертензия',
                        options: [
                            ['Нет', 'Нет'],
                            ['Есть, терапию не получает', 'Есть, терапию не получает'],
                            ['Компенсирована терапией', 'Компенсирована терапией'],
                            ['Не компенсирована терапией', 'Не компенсирована терапией'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'diabetes-mellitus-2-type',
                        name: 'diabetes-mellitus-2-type',
                        placeholder: 'Сахарный диабет 2-го типа',
                        options: [
                            ['Нет признаков', 'Нет признаков'],
                            ['Инсулинорезистентность, гипергликемия или нарушение толерантности к глюкозе', 'Инсулинорезистентность, гипергликемия или нарушение толерантности к глюкозе'],
                            ['Прежние дозы пероральных препаратов', 'Прежние дозы пероральных препаратов'],
                            ['Снижение доз пероральных препаратов', 'Снижение доз пероральных препаратов'],
                            ['Инсулинотерапия', 'Инсулинотерапия'],
                        ],
                        hasConnection: 'diabetes-сompensation',
                        // required: true,
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'compensation-SD',
                        name: 'compensation-SD',
                        placeholder: 'Срок наступления компенсации СД',
                        options: [
                            ['1 неделя', '1 неделя'],
                            ['2 недели', '2 недели'],
                            ['3 недели', '3 недели'],
                            ['4 недели', '4 недели'],
                            ['5 недель', '5 недель'],
                            ['более 6 недель', 'более 6 недель'],
                        ],
                        connected: 'diabetes-сompensation',
                        required: false,
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'gerd',
                        name: 'gerd',
                        placeholder: 'ГЭРБ',
                        options: [
                            ['Нет симптомов', 'Нет симптомов'],
                            ['Периодически возникающие симптомы, купируются самостоятельно', 'Периодически возникающие симптомы, купируются самостоятельно'],
                            ['Периодические возникающие симптомы, купируются препаратами', 'Периодические возникающие симптомы, купируются препаратами'],
                            ['Ежедневный прием препаратов', 'Ежедневный прием препаратов'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'dyslipidemia',
                        name: 'dyslipidemia',
                        placeholder: 'Дислипидемия',
                        options: [
                            ['Да', 'Да'],
                            ['Нет', 'Нет'],
                            ['Не оценивалось', 'Не оценивалось'],
                        ],
                        info: {
                            id: 'dyslipidemia-info',
                            content: 'Только для повышенного уровня липидов/холестерола без учета терапии статинами',
                            theme: 'violet',
                        },
                        required: false,
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'atherosclerosis',
                        name: 'atherosclerosis',
                        placeholder: 'Атеросклероз',
                        options: [
                            ['Да', 'Да'],
                            ['Нет', 'Нет'],
                            ['Не оценивалось', 'Не оценивалось'],
                        ],
                        info: {
                            id: 'atherosclerosis-info',
                            content: 'Включая ИБС, ОИМ, коронарное шунтирование, ОНМК, перемежающуюся хромоту',
                            theme: 'violet',
                        },
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'apnea',
                        name: 'apnea',
                        placeholder: 'Апноэ',
                        options: [
                            ['Да', 'Да'],
                            ['Нет', 'Нет'],
                            ['Не оценивалось', 'Не оценивалось'],
                        ],
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'bronchial-asthma',
                        name: 'bronchial-asthma',
                        placeholder: 'Бронхиальная астма',
                        options: [
                            ['Нет', 'Нет'],
                            ['Ингалятор', 'Ингалятор'],
                            ['Небулайзер/пероральные стероиды', 'Небулайзер/пероральные стероиды'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'functional-status',
                        name: 'functional-status',
                        placeholder: 'Функциональный статус',
                        options: [
                            ['3 этажа без отдыха', '3 этажа без отдыха'],
                            ['1 этаж без отдыха', '1 этаж без отдыха'],
                            ['Половина лестничного пролета', 'Половина лестничного пролета'],
                            ['Не передвигается самостоятельно / Не выходит из дома', 'Не передвигается самостоятельно / Не выходит из дома'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'back-and-limb-pain',
                        name: 'back-and-limb-pain',
                        placeholder: 'Боль в спине и конечностях',
                        options: [
                            ['Нет симптомов', 'Нет симптомов'],
                            ['Периодически возникающие симптомы', 'Периодически возникающие симптомы'],
                            ['Регулярный прием препаратов', 'Регулярный прием препаратов'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'hernia',
                        name: 'hernia',
                        placeholder: 'Грыжа',
                        options: [
                            ['Нет', 'Нет'],
                            ['Пупочная', 'Пупочная'],
                            ['Вентральная', 'Вентральная'],
                            ['Паховая', 'Паховая'],
                            ['ГПОД', 'ГПОД'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'sagging-skin',
                        name: 'sagging-skin',
                        placeholder: 'Обвисание кожи',
                        options: [
                            ['Нет', 'Нет'],
                            ['Обвисания кожи нет, есть морщинистость', 'Обвисания кожи нет, есть морщинистость'],
                            ['Выраженное обвисание кожи на руках, бедрах', 'Выраженное обвисание кожи на руках, бедрах'],
                            ['Выраженное обвисание кожи на животе', 'Выраженное обвисание кожи на животе'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'SELECT',
                    data: {
                        id: 'fat-apron',
                        name: 'fat-apron',
                        placeholder: 'Жировой фартук',
                        options: [
                            ['Нет', 'Нет'],
                            ['Значительная кожно-жировая складка', 'Значительная кожно-жировая складка'],
                            ['Воспаление в складках', 'Воспаление в складках'],
                            ['Затрудняет ходьбу', 'Затрудняет ходьбу'],
                            ['Целлюлит/изъязвление', 'Целлюлит/изъязвление'],
                        ],
                        required: false,
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'urinary-incontinence',
                        name: 'urinary-incontinence',
                        placeholder: 'Недержание мочи',
                        options: [
                            ['Да', 'Да'],
                            ['Нет', 'Нет'],
                        ],
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'depression',
                        name: 'depression',
                        placeholder: 'Депрессия',
                        options: [
                            ['Да', 'Да'],
                            ['Нет', 'Нет'],
                        ],
                    },
                },
                {
                    type: 'RADIO-GROUP',
                    data: {
                        id: 'cholelithiasis',
                        name: 'cholelithiasis',
                        placeholder: 'Желчнокаменная болезнь',
                        options: [
                            ['Да', 'Да'],
                            ['Нет', 'Нет'],
                        ],
                    },
                },
            ],

            additionalGroups: [
                // Женское здоровье
                {
                    name: 'Женское здоровье',
                    addClass: 'group--simple',
                    active: window.PATIENT_DATA.gender === 'Женский' ? true : false,
                    content: [
                        {
                            type: 'SELECT',
                            data: {
                                id: 'polycystic-ovary',
                                name: 'polycystic-ovary',
                                placeholder: 'Синдром поликистозных яичников',
                                options: [
                                    ['Нет', 'Нет'],
                                    ['СПКЯ без терапии', 'СПКЯ без терапии'],
                                    ['СПКЯ с терапией', 'СПКЯ с терапией'],
                                    ['Бесплодие', 'Бесплодие'],
                                ],
                                // required: true,
                            },
                        },
                        {
                            type: 'SELECT',
                            data: {
                                id: 'menstrual-function',
                                name: 'menstrual-function',
                                placeholder: 'Менструальная функция',
                                options: [
                                    ['Регулярный цикл', 'Регулярный цикл'],
                                    ['Нерегулярный цикл', 'Нерегулярный цикл'],
                                    ['Гиперменорея', 'Гиперменорея'],
                                    ['Постменопауза', 'Постменопауза'],
                                    ['Аменорея', 'Аменорея'],
                                    ['Гистерэктомия в анамнезе', 'Гистерэктомия в анамнезе'],
                                ],
                                required: false,
                            },
                        },
                        {
                            type: 'SELECT',
                            data: {
                                id: 'onset-of-pregnancy',
                                name: 'onset-of-pregnancy',
                                placeholder: 'Наступление беременности',
                                options: [
                                    ['Беременность не наступила', 'Беременность не наступила'],
                                    ['Беременность в результате ЭКО', 'Беременность в результате ЭКО'],
                                    ['Безуспешное ЭКО', 'Безуспешное ЭКО'],
                                    ['Естественная беременность', 'Естественная беременность'],
                                ],
                                required: false,
                            },
                        },
                    ],
                },
            ],
        },
        // Дополнительная информация
        {
            id: 'additional-information',
            title: 'Дополнительная информация',
            addClass: ['group--simple', 'group--parent'],
            fields: [
                {
                    type: 'TEXTAREA',
                    data: {
                        id: 'observation-additional',
                        name: 'observation-additional',
                        type: 'text',
                        placeholder: 'Дополнительная информация',
                        required: false,
                        addClass: ['long', 'input-custom--violet'],
                    },
                },
            ],
        },
    ],
};

// Complication для просмотра
const tempateComplication = {
    id: 'complication',
    name: `Раннее осложнение № `,
    addClass: ['group--simple'],
    dataBlocks: [
        {
            name: 'date-of-detection-complication',
            title: 'Дата выявления осложнения',
        },
        {
            name: 'main-complication',
            title: 'Основное осложнение',
        },
        {
            name: 'type-of-complication',
            title: 'Тип осложнения*',
        },
        {
            name: 'scale-complication',
            title: 'Зона кровотечения',
        },
        {
            name: 'localization-complication',
            title: 'Локализация',
        },
        {
            name: 'by-Clavien-Dindo-complication',
            title: 'Тяжесть осложнения по Clavien-Dindo',
        },
        {
            name: 'note-complication',
            title: 'Примечания',
        },
    ],
    additionalGroups: [],
};

const templateInterventionView = {
    name: 'Повторное вмешательство № ',
    addClass: 'group--simple',
    active: true,
    dataBlocks: [
        {
            name: 'date-repeated-operation',
            title: 'Дата повторной операции',
        },
        {
            name: 'hours-from-main-operation',
            title: 'Часов прошло от основной операции',
        },
        {
            name: 'method-repeated-operation',
            title: 'Метод повторной операции',
        },
        {
            name: 'type-repeated-operation',
            title: 'Тип повторной операции',
        },
        {
            name: 'note-intervention',
            title: 'Примечания',
        },
    ],
};

// Complication для редактирования
const complicationEditTemplate = {
    'date-of-detection-complication': '',
    'main-complication': '',
    'scale-complication': '',
    'localization-complication': '',
    'type-of-complication': '',
    'complications-by-Clavien-Dindo': '',
};
const interventionEditTemplate = {
    'date-repeated-operation': '',
    'hours-from-main-operation': '',
    'method-repeated-operation': '',
    'type-repeated-operation': '',
    'note-intervention': '',
};

const outdatedData = {
    id: 'outdatedData',
    name: 'Значения из прошлого реестра',
    addClass: ['group--simple'],
    dataBlocks: [],
};

export function createOperationTemplate(operationResponse) {
    const operationType = operationResponse.operationType;
    const operationsRevisionKeys = Object.keys(REVISION_OPERATIONS);
    const operation = operationsRevisionKeys.includes(operationType) ? REVISION_OPERATIONS[operationType] : OPERATIONS[operationType];

    // ОБЩИЙ ШАБЛОН (Общие сведения, Интраопреационные осложнения, Пребывание в стационаре)
    const operationEntity = {
        id: templateOperation.id,
        name: templateOperation.name,
        date: templateOperation.date,
        files: operationResponse.files,
        operation: {
            info: [],
            fields: [],
        },
        observations: [],
    };

    templateOperation.operation.info.forEach((group) => {
        const newGroup = {
            id: group.id,
            name: group.name,
            addClass: group.addClass,
        };

        if (group.dataBlocks) {
            // Создание блоков информации
            newGroup.dataBlocks = group.dataBlocks.map((el) => createInfoBlock(el)).filter(Boolean);
        }
        if (group.additionalGroups) {
            newGroup.additionalGroups = [];
            // Создание блоков информации
            group.additionalGroups.forEach((item) => {
                newGroup.additionalGroups.push({
                    name: item.name,
                    addClass: item.addClass,
                    active: item.active,
                    dataBlocks: item.dataBlocks.map((el) => createInfoBlock(el)).filter(Boolean),
                });
            });
        }

        operationEntity.operation.info.push(newGroup);
    });

    const resultOperationEntity = { ...operationEntity, id: operationResponse.id, date: `(${operationResponse.date})`, name: operationResponse.operationType };

    // ОПЕРАЦИ.
    // Режим просмотра (ключ info)
    const operationSteps = {
        id: 'stages-operation',
        name: 'Этапы операции',
        addClass: ['group--simple', 'group--parent'],
        dataBlocks: [],
        additionalGroups: [],
    };

    if (operation.mainFields) {
        operationSteps.dataBlocks = operation.mainFields.map((field) => createInfoBlock(field)).filter(Boolean);
    }
    if (operation.additionalGroups) {
        // Заполнение этапов операции
        operation.additionalGroups.forEach((group) => {
            operationSteps.additionalGroups.push({
                name: group.name,
                addClass: 'group--simple',
                active: true,
                dataBlocks: group.content.map((field) => createInfoBlock(field)).filter(Boolean),
            });
        });
    }

    if (operationSteps.dataBlocks?.length > 0 || operationSteps.additionalGroups?.length > 0) {
        resultOperationEntity.operation.info.splice(1, 0, operationSteps);
    }
    // - - -

    // НАБЛЮДЕНИЯ
    // Режим просмотра (ключ info)
    const observations = [];
    operationResponse.observations.forEach((observationInResponse) => {
        const observationData = {
            txt: observationInResponse.txt,
            id: observationInResponse.id,
            files: [...observationInResponse.files],
            info: templateObservation.info.map((group) => {
                const resultGroup = {
                    name: group.name,
                    addClass: group.addClass,
                    dataBlocks: group.dataBlocks
                        .map((el) => {
                            const obsResponseEntity = observationInResponse.fields.find((obs) => obs.name === el.name);
                            if (obsResponseEntity) {
                                return {
                                    name: el.name,
                                    title: el.title,
                                    value: obsResponseEntity.value,
                                };
                            }
                        })
                        .filter(Boolean),
                };

                if (group.additionalGroups) {
                    resultGroup.additionalGroups = [];
                    group.additionalGroups.forEach((group) => {
                        const resultAdditionalGroup = {
                            name: group.name,
                            addClass: 'group--simple',
                            active: true,
                            dataBlocks: group.dataBlocks
                                .map((el) => {
                                    const obsResponseEntity = observationInResponse.fields.find((obs) => obs.name === el.name);
                                    if (obsResponseEntity) {
                                        return {
                                            name: el.name,
                                            title: el.title,
                                            value: obsResponseEntity.value,
                                        };
                                    }
                                })
                                .filter(Boolean),
                        };

                        if (resultAdditionalGroup.dataBlocks.length > 0) {
                            resultGroup.additionalGroups.push(resultAdditionalGroup);
                        }
                    });
                }

                return resultGroup;
            }),
            field: [],
        };

        // НАБЛЮДЕНИЯ. УСТАРЕВШИЕ ЗНАЧЕНИЯ
        if (observationInResponse.outdatedData.length > 0) {
            const outdatedDataClone = structuredClone(outdatedData);
            observationInResponse.outdatedData.forEach((item) => outdatedDataClone.dataBlocks.push(item));
            observationData.outdatedData = outdatedDataClone;
        }

        observations.push(observationData);
    });

    // НАБЛЮДЕНИЯ
    // Добавляем поля для режима редактирования
    observations.map((obs) => {
        const currentObservation = operationResponse.observations.find((observation) => observation.id === obs.id);

        obs.fields = templateObservation.fields.map((group) => {
            const groupFilledCopy = {
                id: group.id,
                title: group.title,
                addClass: group.addClass,
            };

            if (group.fields) {
                groupFilledCopy.fields = group.fields.map((item) => {
                    // const currentObservation = operationResponse.observations.find((observation) => observation.id === obs.id);
                    const currentObservationField = currentObservation.fields.find((field) => field.name === item.data.name);
                    const itemClone = structuredClone(item);
                    if (currentObservationField) {
                        itemClone.data.value = currentObservationField.value;
                    }
                    return itemClone;
                });
            }
            if (group.additionalGroups) {
                groupFilledCopy.additionalGroups = group.additionalGroups.map((addGroup) => {
                    return {
                        name: addGroup.name,
                        addClass: addGroup.addClass,
                        id: addGroup.id,
                        active: addGroup?.active,
                        content: addGroup.content.map((item) => {
                            // const currentObservation = operationResponse.observations.find((observation) => observation.id === obs.id);
                            const currentObservationField = currentObservation.fields.find((field) => field.name === item.data.name);
                            const itemClone = structuredClone(item);
                            if (currentObservationField) {
                                itemClone.data.value = currentObservationField.value;
                            }
                            return itemClone;
                        }),
                    };
                });
            }

            return groupFilledCopy;
        });

        // УДАЛЯЕМ ГРУППУ БАНДАЖИРОВАНИЕ ЖЕЛУДКА (Если нет Checkbox, c name 'adjusting-bandage')
        const adjustingBandage = currentObservation.fields.find((field) => field.name === 'adjusting-bandage');
        if (!adjustingBandage) {
            obs.fields = obs.fields.filter((el) => el.id !== 'gastric-banding');
        }
    });

    // ОПЕРАЦИИ
    // На основе resultOperationEntity.operation.info формируем группы с полями для редактирования.
    resultOperationEntity.operation.info.map((group) => {
        const groupForFields = {
            id: group.id,
            title: group.name,
            addClass: group.addClass,
            fields: [],
        };

        // ЭТАПЫ ОПЕРАЦИИ
        if (group.id === 'stages-operation') {
            if (operation.mainFields) {
                groupForFields.fields = operation.mainFields
                    .map((el) => {
                        const operationResp = operationResponse.operationFields.find((field) => field.name === el.data.name);
                        const objOperationField = structuredClone(el);

                        if (operationResp) {
                            objOperationField.data.value = operationResp.value;
                        }

                        if (operationResp && objOperationField.type === 'CHECKBOX') {
                            objOperationField.data.checked = true;
                        }

                        return objOperationField;
                    })
                    .filter(Boolean);
            }
            if (operation.additionalGroups) {
                groupForFields.additionalGroups = operation.additionalGroups.map((group, i) => {
                    const addGroup = {
                        name: group.name,
                        addClass: ['group--simple'],
                        // Чтобы все группы операции сразу отображались, то поставь true
                        active: group.active,
                        number: i + 1,
                        observe: group.observe,
                        content: [],
                    };

                    addGroup.content = group.content
                        .map((el) => {
                            const operationResp = operationResponse.operationFields.find((field) => field.name === el.data.name);
                            const objOperationField = structuredClone(el);

                            if (operationResp) {
                                objOperationField.data.value = operationResp.value;
                            }

                            if (operationResp && objOperationField.type === 'CHECKBOX') {
                                objOperationField.data.checked = true;
                            }

                            if (operationResp && objOperationField.type === 'RADIO-GROUP') {
                                objOperationField.data.attributes = [{ name: 'data-value', value: operationResp.value }];
                            }

                            return objOperationField;
                        })
                        .filter(Boolean);

                    return addGroup;
                });
            }

            const element = operationEntity.operation.fields.find((item) => item.id === 'general-information');
            const elementIndex = operationEntity.operation.fields.indexOf(element);
            operationEntity.operation.fields.splice(elementIndex + 1, 0, groupForFields);
            // Если это была группа "Этапы Операции", то дальше функция не выполняется
            return;
        }

        // Добавляем value к полям
        const groupTemplate = templateOperation.operation.fields.find((el) => el.id === group.id);
        const groupCopy = {
            id: groupTemplate.id,
            title: groupTemplate.title,
            addClass: groupTemplate.addClass,
            subtitle: groupTemplate?.subtitle,
        };

        if (groupTemplate.fields) {
            groupCopy.fields = groupTemplate.fields.map((item) => {
                const responseField = operationResponse.operationFields.find((field) => field.name === item.data.name);
                const objField = structuredClone(item);
                if (responseField) {
                    objField.data.value = responseField.value;
                }
                if (responseField && objField.type === 'CHECKBOX') {
                    objField.data.checked = true;
                }
                return objField;
            });
        }
        if (groupTemplate.additionalGroups) {
            groupCopy.additionalGroups = groupTemplate.additionalGroups.map((group) => {
                return {
                    name: group.name,
                    addClass: group.addClass,
                    active: group.active,
                    id: group.id,
                    connected: group.connected,
                    content: group.content.map((item) => {
                        const responseField = operationResponse.operationFields.find((field) => field.name === item.data.name);
                        const objField = structuredClone(item);
                        if (responseField) {
                            objField.data.value = responseField.value;
                        }
                        if (responseField && objField.type === 'CHECKBOX') {
                            objField.data.checked = true;
                        }
                        return objField;
                    }),
                    // .filter(Boolean),
                };
            });
        }
        operationEntity.operation.fields.push(groupCopy);
    });

    // ОПЕРАЦИЯ. УСТАРЕВШИЕ ЗНАЧЕНИЯ
    if (operationResponse.outdatedData.length > 0) {
        const outdatedDataClone = structuredClone(outdatedData);
        operationResponse.outdatedData.forEach((item) => outdatedDataClone.dataBlocks.push(item));
        resultOperationEntity.outdatedData = outdatedDataClone;
    }

    // ОСЛОЖНЕНИЯ
    // Режим просмотра
    operationResponse.complications?.forEach((item, index) => {
        const newComplication = structuredClone(tempateComplication);

        // Добавили номер осложнения
        newComplication.name = tempateComplication.name + (index + 1);
        newComplication.dataBlocks = newComplication.dataBlocks
            .map((block) => {
                const responseField = item.fields.find((el) => el.name === block.name);
                // Добавили value
                if (responseField) {
                    block.value = responseField.value;
                    return block;
                }
            })
            .filter(Boolean);

        // Добавили повторные вмешательства
        if (item.interventions) {
            newComplication.additionalGroups = item.interventions.map((int, i) => {
                const interventionClone = structuredClone(templateInterventionView);
                interventionClone.name = interventionClone.name + (i + 1);
                interventionClone.dataBlocks = interventionClone.dataBlocks
                    .map((el) => {
                        const responseInt = int.fields.find((field) => field.name === el.name);
                        if (responseInt) {
                            el.value = responseInt.value;
                            return el;
                        }
                    })
                    .filter(Boolean);
                return interventionClone;
            });
        }
        operationEntity.operation.info.push(newComplication);
    });

    // ОСЛОЖНЕНИЯ
    // Режим редактирования
    operationResponse.complications?.forEach((item) => {
        const editComplicationEntity = {
            complication: true,
            interventions: [],
        };

        const copyTemplate = { ...complicationEditTemplate };

        item.fields.forEach((el) => {
            copyTemplate[el.name] = el.value;
        });

        editComplicationEntity.data = { ...copyTemplate, addClass: 'group--simple' };

        if (item.interventions) {
            item.interventions.forEach((int) => {
                const copyTemplateInt = { ...interventionEditTemplate };
                int.fields.forEach((el) => {
                    copyTemplateInt[el.name] = el.value;
                });
                editComplicationEntity.interventions.push(copyTemplateInt);
            });
        }

        resultOperationEntity.operation.fields.push(editComplicationEntity);
    });

    // Удаляем пустые блоки и группы Операций | Наблюдений
    // resultOperationEntity.observations = observations; (Присваиваем наблюдения к операции)
    resultOperationEntity.observations = observations.map((obs) => ({ ...obs, info: filterEmptyElements(obs.info) }));
    resultOperationEntity.operation.info = filterEmptyElements(resultOperationEntity.operation.info);

    function createInfoBlock(field, type = 'operation') {
        let arrayFields;
        switch (type) {
            case 'operation':
                arrayFields = operationResponse.operationFields;
                break;
            case 'observation':
                arrayFields = operationResponse.observations.fields;
                break;
        }

        if (field.type === 'SUBTITLE') {
            return {
                name: 'SUBTITLE',
                value: field.data.subtitle,
            };
        }

        const title = field.title || field.data.placeholder;
        const currentElement = arrayFields.find((el) => {
            const fieldName = field?.data?.name || field.name;
            if (el.name.trim() === fieldName) {
                return true;
            }
        });
        // console.log(currentElement);
        // if (currentElement && currentElement.name.includes('emergency-situation')) {
        //     return {
        //         title: title,
        //         name: currentElement.name.trim(),
        //         value: typeof currentElement.value !== 'number' && currentElement.value.includes('|') ? currentElement.value.replace('|', ',<br>') : currentElement.value,
        //     };
        // }
        if (currentElement) {
            /\/g/;
            return {
                name: currentElement.name.trim(),
                title: title,
                value: typeof currentElement.value !== 'number' && currentElement.value.includes('|') ? currentElement.value.replace(/\/g/, ',<br>') : currentElement.value,
            };
        }
    }

    // console.log(resultOperationEntity);
    return resultOperationEntity;
}

function filterEmptyElements(array) {
    return array.filter((item) => {
        if ('dataBlocks' in item && 'additionalGroups' in item) {
            if (isEmptyArr(item.dataBlocks) && isEmptyArr(item.additionalGroups)) {
                return false;
            }
            if (isEmptyArr(item.dataBlocks)) {
                delete item.dataBlocks;
            }
            if (isEmptyArr(item.additionalGroups)) {
                delete item.additionalGroups;
            }
            return true;
        }

        if ('dataBlocks' in item) {
            if (isEmptyArr(item.dataBlocks)) return false;
        }

        if ('additionalGroups' in item) {
            item.additionalGroups = item.additionalGroups.filter((el) => !isEmptyArr(el.dataBlocks));
            if (isEmptyArr(item.additionalGroups)) return false;
        }

        return true;
    });
}

function isEmptyArr(arr) {
    if (arr.length === 0) {
        return true;
    }
    return false;
}
