/* global require */

require('what-input/dist/what-input.js');
require('../img/sprite-svg/sprite-svg.js');
require('../js/global-scripts.js');
require('../js/validate.js');
require('../js/backend-template.js');
require('../components/btn/btn.js');
require('../components/calendar/calendar.js');
require('../components/chip/chip.js');
require('../components/custom-tooltip/custom-tooltip.js');
require('../components/file/file.js');
require('../components/file-loader/file-loader.js');
require('../components/filters/filters.js');
require('../components/group/group.js');
require('../components/group-radio-buttons/group-radio-buttons.js');
require('../components/info/info.js');
require('../components/input-custom/input-custom.js');
require('../components/itc-custom-select/itc-custom-select.js');
require('../components/modal/modal.js');
require('../components/modal-remove/modal-remove.js');
require('../components/modal-transfer-patient/modal-transfer-patient.js');
require('../components/pill/pill.js');
require('../components/search/search.js');
require('../components/side-modal/side-modal.js');
require('../components/tabs/tabs.js');
require('../components/tooltipActions/tooltipActions.js');
require('../components/user/user.js');
require('../components/viewing-modal/viewing-modal.js');
require('../blocks/control-panel/control-panel.js');
require('../blocks/form-creating-observation/form-creating-observation.js');
require('../blocks/form-creating-operation/form-creating-operation.js');
require('../blocks/form-new-patient/form-new-patient.js');
require('../blocks/header/header.js');
require('../blocks/hero/hero.js');
require('../blocks/login/login.js');
require('../blocks/patient-card/patient-card.js');
require('../blocks/patient-table/patient-table.js');
require('../blocks/profile/profile.js');
require('../blocks/side-modal-patient-card/side-modal-patient-card.js');
require('../blocks/support/support.js');
