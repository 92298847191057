const passwordInputs = document.querySelectorAll('input[type="password"]');

if (passwordInputs.length) {
    passwordInputs.forEach((input) => {
        const wrapper = input.closest('.input-custom');
        const showPasswordBtn = wrapper.querySelector('.button-eye');
        showPasswordBtn?.addEventListener('click', () => showPasswordToogle(input));
    });

    function showPasswordToogle(input) {
        console.log(input);
        if (input.type === 'password') {
            input.type = 'text';
        } else {
            input.type = 'password';
        }
    }
}
