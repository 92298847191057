if (document.querySelector('.wrapper').classList.contains('patient-card')) {
    const selectSurgeons = new window.ItcCustomSelect(`#transfer-to-surgeon`, {
        name: 'transfer-to-surgeon',
        placeholder: 'Выберите врача',
        options: window.SURGEONS_TRANSFER || [
            ['List item 1', 'List item 1'],
            ['List item 2', 'List item 2'],
            ['List item 1', 'List item 1'],
            ['List item 1', 'List item 1'],
        ],
    });

    let observer = new MutationObserver((mutations, observer) => {
        if (mutations[0].target.value) {
            btnTransfer.removeAttribute('disabled');
        }
    });
    observer.observe(selectSurgeons._elToggle, { attributes: true, attributeFilter: ['value'] });

    const btnTransfer = document.querySelector('.modal-transfer-patient__btn');
    btnTransfer.addEventListener('click', tranferOtherSurgeon);

    function tranferOtherSurgeon() {
        btnTransfer.setAttribute('disabled', true);
        fetch(`/personal/patient/${window.PATIENT_DATA.id}/change_surgeon`, {
            method: 'POST',
            body: JSON.stringify({
                _token: document.querySelector('meta[name="csrf-token"]').content,
                value: selectSurgeons.value,
            }),
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        })
            .then((response) => {
                if (!response.ok) return;
                location.href = '/personal';
            })
            .finally(() => {
                btnTransfer.setAttribute('disabled', true);
                window.closeModal(true);
            });
    }
}
