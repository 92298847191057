import { Complication, RepeatedIntervention } from '../../blocks/complications/complication';
import { checkFilledInput } from '../../js/validate';

const removeData = {
    'remove-work': {
        title: 'Удаление места работы',
        subtitle: 'Вы больше не сможете добавлять операции с этой клиникой,<br> вы уверены?',
    },
    'remove-assistant': {
        title: 'Удаление ассистента',
        subtitle: 'Это действие необратимо,<br> вы уверены?',
    },
    'remove-operation': {
        title: 'Удаление операции',
        subtitle: 'Это действие необратимо,<br> вы уверены?',
    },
    'remove-observation': {
        title: 'Удаление наблюдения',
        subtitle: 'Это действие необратимо,<br> вы уверены?',
    },
    'remove-file': {
        title: 'Удаление файла',
        subtitle: 'Это действие необратимо,<br> вы уверены?',
    },
    'remove-complication': {
        title: 'Удаление осложнения',
        subtitle: 'Это действие необратимо, вы уверены?',
    },
    'remove-intervention': {
        title: 'Удаление вмешательства',
        subtitle: 'Это действие необратимо, вы уверены?',
    },
    'remove-patient': {
        title: 'Удаление карты пациента',
        subtitle: 'Текущая карта, все операции и наблюдения будут удалены,<br> вы уверены?',
    },
};

document.addEventListener('DOMContentLoaded', () => {
    const removeModal = document.querySelector('.modal-remove');

    if (!removeModal) return;

    const btnRemove = removeModal.querySelector('.modal-remove__remove-btn');

    document.addEventListener('click', (event) => {
        const trashBtn = event.target.closest('[data-modal-name]');
        if (!trashBtn) return;

        const itemWrap = removeModal.querySelector('.modal-remove__item');

        switch (trashBtn.dataset.modalName) {
            case 'remove-work':
                const place = event.target.closest('.place-of-work');
                const placeClone = place.cloneNode(true);
                placeClone.querySelector('.place-of-work__remove')?.remove();
                placeClone.removeAttribute('data-number');
                itemWrap.innerHTML = '';
                removeModal.querySelector('.modal-remove__item').append(placeClone);
                btnRemove.dataset.removeWork = place.dataset.number;
                btnRemove.dataset.id = place.dataset.id;

                // btnRemove.addEventListener('click', removeWork)
                break;
            case 'remove-assistant':
                let assistant;
                let assistantClone;

                if (trashBtn.dataset.number) {
                    assistant = document.querySelector(`.assistant[data-number='${trashBtn.dataset.number}' ]`);
                    btnRemove.dataset.removeAssistant = trashBtn.dataset.number;
                    btnRemove.dataset.id = trashBtn.dataset.id;
                } else {
                    assistant = event.target.closest('.assistant');
                    btnRemove.dataset.removeAssistant = assistant.dataset.number;
                    btnRemove.dataset.id = assistant.dataset.id;
                }

                assistantClone = assistant.cloneNode(true);
                assistantClone.querySelector('.assistant__btns')?.remove();
                itemWrap.innerHTML = '';

                removeModal.querySelector('.modal-remove__item').append(assistantClone);

                break;
            case 'remove-observation':
                btnRemove.dataset.removeObservationId = trashBtn.dataset.observationId;
                break;
            case 'remove-operation':
                btnRemove.dataset.removeOperationId = trashBtn.dataset.operationId;
                break;
            case 'remove-file':
                btnRemove.dataset.removeFileId = trashBtn.dataset.fileId;
                break;
            case 'remove-complication':
                btnRemove.dataset.removeComplication = trashBtn.dataset.complicationId;
                break;
            case 'remove-intervention':
                btnRemove.dataset.removeIntervention = trashBtn.dataset.interventionId;
                break;
            case 'remove-patient':
                btnRemove.dataset.removePatient = trashBtn.dataset.patientId;
                break;
        }

        if (removeData[trashBtn.dataset.modalName]) {
            removeModal.querySelector('.modal-remove__title').innerText = removeData[trashBtn.dataset.modalName].title;
            removeModal.querySelector('.modal-remove__subtitle').innerHTML = removeData[trashBtn.dataset.modalName]?.subtitle;
        }
    });

    // Нажатие на кнопку "Удалить" внутри модального окна.
    btnRemove.addEventListener('click', removeItem);
    function removeItem() {
        let itemNumber;
        let deletedEl;
        let pageSection;

        if (btnRemove.dataset.removeWork) {
            fetch('/personal/profile/edit', {
                method: 'POST',
                body: JSON.stringify({
                    id: btnRemove.dataset.id,
                    action: 'remove-workplace',
                    _token: document.querySelector('meta[name="csrf-token"]').content,
                }),
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            })
                .then((response) => {
                    if (!response.ok) return;
                    itemNumber = btnRemove.dataset.removeWork;
                    deletedEl = document.querySelector(`.place-of-work[data-number='${itemNumber}']`);
                    pageSection = deletedEl.closest('.page-section');
                    deletedEl?.remove();

                    if (!pageSection.querySelectorAll('.place-of-work')?.length) {
                        pageSection.classList.remove('not-empty');
                    }
                    changeItemNumbers('place-of-work');
                })
                .finally(() => {
                    clearBtnRemove();
                    window.closeModal(true);
                });
        } else if (btnRemove.dataset.removeAssistant) {
            fetch('/personal/profile/edit', {
                method: 'POST',
                body: JSON.stringify({
                    id: btnRemove.dataset.id,
                    action: 'remove-assistant',
                    _token: document.querySelector('meta[name="csrf-token"]').content,
                }),
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            })
                .then((response) => {
                    if (!response.ok) return;
                    itemNumber = btnRemove.dataset.removeAssistant;
                    deletedEl = document.querySelector(`.assistant[data-number='${itemNumber}']`);
                    pageSection = deletedEl.closest('.page-section');
                    deletedEl?.remove();

                    if (!pageSection.querySelectorAll('.assistant')?.length) {
                        pageSection.classList.remove('not-empty');
                    }
                    changeItemNumbers('assistant');
                })
                .finally(() => {
                    clearBtnRemove();
                    window.closeModal(true);
                });
        } else if (btnRemove.dataset.removeObservationId) {
            const operationId = document.querySelector('.i-modal.is-active').dataset.operationId;
            const observationId = btnRemove.dataset.removeObservationId;
            const operation = window.OPERATION_DATA.find((el) => el.id === Number(operationId));
            operation.observations = operation.observations.filter((el) => el.id !== Number(observationId));

            fetch(`/personal/observation/${observationId}/delete`, {
                method: 'POST',
                body: JSON.stringify({
                    _token: document.querySelector('meta[name="csrf-token"]').content,
                }),
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            })
                .then((response) => {
                    if (!response.ok) return;
                    deletedEl = document.querySelector(`.pill__observation[data-observation-id='${btnRemove.dataset.removeObservationId}']`);
                    const operation = window.OPERATION_DATA.find((el) => el.id === Number(operationId));
                    operation.observations = operation.observations.filter((el) => el.id !== Number(observationId));
                    deletedEl?.remove();
                    location.reload();
                })
                .finally(() => {
                    clearBtnRemove();
                    window.closeModal(true);
                });
        } else if (btnRemove.dataset.removeOperationId) {
            const operationId = btnRemove.dataset.removeOperationId;
            fetch(`/personal/operation/${operationId}/delete`, {
                method: 'POST',
                body: JSON.stringify({
                    _token: document.querySelector('meta[name="csrf-token"]').content,
                }),
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            })
                .then((response) => {
                    if (!response.ok) return;
                    deletedEl = document.querySelector(`.pill[data-operation-id='${btnRemove.dataset.removeOperationId}']`);
                    window.OPERATION_DATA = window.OPERATION_DATA.filter((el) => el.id !== Number(operationId));
                    deletedEl.remove();
                    location.reload();
                })
                .finally(() => {
                    clearBtnRemove();
                    window.closeModal(true);
                });
        } else if (btnRemove.dataset.removeFileId) {
            deletedEl = document.querySelector(`.file[data-file-id='${btnRemove.dataset.removeFileId}']`);
            deletedEl?.remove();

            const formData = new FormData();
            formData.set('action', 'delete');
            formData.set('type', 'patient');
            formData.set('id', btnRemove.dataset.removeFileId);
            formData.set('_token', document.querySelector('meta[name="csrf-token"]').content);
            fetch(`${location.href}/file`, {
                method: 'POST',
                body: formData,
            })
                .then(() => {
                    location.reload();
                })
                .finally(() => {
                    window.closeModal(true);
                })
                .catch((e) => {
                    console.error(e);
                });
        } else if (btnRemove.hasAttribute('data-remove-complication')) {
            const complication = document.querySelector(`.complication[data-complication-id="${btnRemove.dataset.removeComplication}"]`);
            Complication.deleteComplication(complication);
            window.closeModal();
            clearBtnRemove();
        } else if (btnRemove.hasAttribute('data-remove-intervention')) {
            const intervention = document.querySelector(`.intervention[data-intervention-id="${btnRemove.dataset.removeIntervention}"]`);
            RepeatedIntervention.deleteIntervention(intervention);
            window.closeModal();
            // Проверяем ещё раз заполненность группы
            // "Раннее осложнение" после удаления Повторного вмешательства
            document.querySelectorAll('.complication').forEach((el) => {
                checkFilledInput(el);
            });
            clearBtnRemove();
        } else if (btnRemove.hasAttribute('data-remove-patient')) {
            fetch(`/personal/patient/${window.PATIENT_DATA.id}/delete`, {
                method: 'POST',
                body: JSON.stringify({
                    _token: document.querySelector('meta[name="csrf-token"]').content,
                }),
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            })
                .then((response) => {
                    if (!response.ok) return;
                    location.href = '/personal';
                })
                .finally(() => {
                    clearBtnRemove();
                    window.closeModal(true);
                });
        }
    }

    const removeObserver = new MutationObserver((mutations) => {
        if (!mutations[0].target.classList.contains('is-active')) {
            clearBtnRemove();
        }
    });
    removeObserver.observe(removeModal, { attributeFilter: ['class'] });

    function clearBtnRemove() {
        btnRemove.removeAttribute('data-remove-work');
        btnRemove.removeAttribute('data-remove-assistant');
        btnRemove.removeAttribute('data-remove-observation-id');
        btnRemove.removeAttribute('data-remove-operation-id');
        btnRemove.removeAttribute('data-id');
        btnRemove.removeAttribute('data-remove-patient');
    }

    function changeItemNumbers(selector) {
        const items = document.querySelectorAll(`.${selector}`);
        items.forEach((item, i) => {
            item.dataset.number = i + 1;
            item.querySelector(`.${selector}__number`).innerText = `№${i + 1}`;
        });
    }
});
