if (!window.OPERATION_DATA && !window.PATIENT_DATA) {
    window.OPERATION_DATA = [
        {
            id: 1,
            operationType: 'sadi',
            date: '02.08.2020',
            operationFields: [
                {
                    name: 'strengthening-stapler-line',
                    value: 'Peristrips',
                },
                {
                    name: 'duration-operation',
                    value: '2321',
                },
                {
                    name: 'operation-additional',
                    value: 'ДОП ИНФО',
                },
                {
                    name: 'reason-removing-balloon',
                    value: 'Эрозия бандажа',
                },
                {
                    name: 'type-of-operation',
                    value: 'Ревизионная (по поводу неэффективности или осложнений предыдущей операции)',
                },
                {
                    name: 'type-of-complication',
                    value: 'Очень СЛОЖНОЕ',
                },
                {
                    name: 'operation-details',
                    value: 'lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem',
                },
                {
                    name: 'intraoperative-complications',
                    value: 'Интраоперационные осложнения',
                },
                {
                    name: 'type-of-revision-operation',
                    value: 'Иная ревизионная операция',
                },
                {
                    name: 'surgeon',
                    value: '122',
                },
                {
                    name: 'choosing-clinic',
                    value: 'ПОДМОСТНАЯ',
                },
                {
                    name: 'vomiting',
                    value: 'Кратковременная, прошла самостоятельно',
                },
                {
                    name: 'emergency-situation2',
                    value: 'Да',
                },
                {
                    name: 'weight-operation',
                    value: '130',
                },
                {
                    name: 'date-operation',
                    value: '20.06.2024',
                },
                {
                    name: 'assistants',
                    value: 'Викториан Виктор Викторович|Тестовый Тест Тестович|Ренат Витальевич К',
                },
                {
                    name: 'positive-leak-test',
                    value: 'ЫЫЫЫЫЫЫЫЫ',
                },
                {
                    name: 'intraoperative-complications-comment',
                    value: 'Комментарий',
                },
                {
                    name: 'ventricle-formed-probe',
                    value: 'Да',
                },
                {
                    name: 'number-cassettes-for-stomach-separation',
                    value: '4',
                    required: false,
                },
                {
                    name: 'probe-diameter',
                    value: '36',
                    required: false,
                },
                {
                    name: 'cassettes-for-stomach-separation',
                    value: 'Черные (4.5-5 мм)',
                    required: false,
                },
                {
                    name: 'distance-from-gatekeeper-to-first-cassette',
                    value: 'Черные (4-4.5-5 мм)',
                    required: false,
                },
                {
                    name: 'strengthening-stapler-line',
                    value: 'Черные (4-4.5-5 мм)',
                    required: false,
                },
                {
                    name: 'additional-processing-hiatus',
                    value: 'Отсечение желудочно-диафрагмальной связки',
                    required: false,
                },
                {
                    name: 'drainage-of-the-abdominal-cavity',
                    value: 'Нет',
                    required: false,
                },
                {
                    name: 'hemostasis-from-staplers',
                    value: 'Гемостаз клипсами',
                    required: false,
                },
                {
                    name: 'thickness-drainage-tube',
                    value: '13 Fr',
                    required: false,
                },
            ],
            files: [
                { id: 122, name: 'Тестовый файл 1', link: 'https://images.wallpaperscraft.ru/image/single/ptitsa_siluet_vektor_134154_1920x1080.jpg', type: 'png' },
                { id: 222, name: 'Тестовый файл 2', link: 'https://media.mythopedia', type: 'docx' },
            ],
            observations: [
                {
                    txt: '10 мес.',
                    id: 1,
                    files: [
                        // 'https://media.mythopedia.com/4COrNhCXTVXcd4evDlaf39/769597f2d641be803f9dfa7b735b12cf/greek-gods-name-generator.jpg?w=640&ar=3:2&fit=crop&crop=top&q=50&auto=compress,format%20640w'
                        { id: 122, name: 'Тестовый файл 1', link: 'https://images.wallpaperscraft.ru/image/single/ptitsa_siluet_vektor_134154_1920x1080.jpg', type: 'png' },
                        { id: 222, name: 'Тестовый файл 2', link: 'https://media.mythopedia', type: 'docx' },
                    ],
                    fields: [
                        {
                            name: 'depression',
                            value: 'Есть',
                        },
                        {
                            name: 'adjusting-bandage',
                            value: 'Да',
                        },
                        {
                            name: 'date-observation',
                            value: '27.06.2024',
                        },
                        {
                            name: 'reason-adjusting-bandage',
                            value: 'Желудочек',
                        },
                        {
                            name: 'intake-of-vitamins',
                            value: 'Да',
                        },
                        {
                            name: 'diabetes-mellitus-2-type',
                            value: 'Прежние дозы пероральных препаратов',
                        },
                        {
                            name: 'signs-of-nutritional-deficiency',
                            value: 'Есть',
                        },
                    ],
                    outdatedData: [
                        // {
                        //     title: 'Наблюдение 1 (Неизвестное поле 1)',
                        //     value: 'Значение неизветсного поля (1)',
                        // },
                        // {
                        //     title: 'Наблюдение 1 (Неизвестное поле 2)',
                        //     value: 'Значение неизветсного поля (2)',
                        // },
                        // {
                        //     title: 'Наблюдение 1 (Неизвестное поле 3)',
                        //     value: 'Значение неизветсного поля (3)',
                        // },
                    ],
                },
                {
                    txt: '33 мес.',
                    id: 2,
                    files: [
                        { id: 1212, name: '', link: '', type: '' },
                        { id: 9493, name: '', link: '', type: '' },
                    ],
                    fields: [
                        {
                            name: 'date-observation',
                            value: '22.22.2222',
                        },
                        {
                            name: 'reason-adjusting-bandage',
                            value: 'Желудочек',
                        },
                    ],
                    outdatedData: [
                        {
                            title: 'Наблюдение 2 (Неизвестное поле 1)',
                            value: 'Значение неизветсного поля (1)',
                        },
                        {
                            title: 'Наблюдение 2 (Неизвестное поле 2)',
                            value: 'Значение неизветсного поля (2)',
                        },
                        {
                            title: 'Наблюдение 2 (Неизвестное поле 3)',
                            value: 'Значение неизветсного поля (3)',
                        },
                    ],
                },
            ],
            complications: [
                {
                    fields: [
                        {
                            name: 'date-of-detection-complication',
                            value: '15.06.2024',
                        },
                        {
                            name: 'main-complication',
                            value: 'ОСЛОЖНЁН',
                        },
                        {
                            name: 'localization-complication',
                            value: 'Из гастроэнтероанастомоза',
                        },
                        {
                            name: 'type-of-complication',
                            value: 'Степлерной линии',
                        },
                        {
                            name: 'by-Clavien-Dindo-complication',
                            value: 'I степень - разрешилось без терапии',
                        },
                        {
                            name: 'note-complication',
                            value: 'Примечание к осложнению',
                        },
                    ],
                    interventions: [
                        {
                            fields: [
                                {
                                    name: 'date-repeated-operation',
                                    value: '10.06.2024',
                                },
                                {
                                    name: 'hours-from-main-operation',
                                    value: '12',
                                },
                                {
                                    name: 'method-repeated-operation',
                                    value: 'Конверсионная лапаротомия - Лапароскопия',
                                },
                                {
                                    name: 'type-repeated-operation',
                                    value: 'Остановка кровотечения',
                                },
                                {
                                    name: 'note-intervention',
                                    value: 'Всё в порядке',
                                },
                            ],
                        },
                    ],
                },
                {
                    fields: [
                        {
                            name: 'date-of-detection-complication',
                            value: '11.11.1911',
                        },
                        {
                            name: 'main-complication',
                            value: 'Несостоятельность',
                        },
                        {
                            name: 'localization-complication',
                            value: 'Из гастроэнтероанастомоза',
                        },
                        {
                            name: 'type-of-complication',
                            value: 'Степлерной линии',
                        },
                        {
                            name: 'by-Clavien-Dindo-complication',
                            value: 'IVa степень - интенсивная терапия (полиорганная недостаточность)',
                        },
                    ],
                    interventions: [
                        {
                            fields: [
                                {
                                    name: 'date-repeated-operation',
                                    value: '12.12.2012',
                                },
                                {
                                    name: 'hours-from-main-operation',
                                    value: '12',
                                },
                                {
                                    name: 'method-repeated-operation',
                                    value: 'Конверсионная лапаротомия - Лапароскопия',
                                },
                                {
                                    name: 'type-repeated-operation',
                                    value: 'Остановка кровотечения',
                                },
                                {
                                    name: 'note-intervention',
                                    value: 'Всё в порядке',
                                },
                            ],
                        },
                    ],
                },
            ],
            outdatedData: [
                {
                    title: 'Операция. Неизвестное поле',
                    value: 'Значение неизветсного поля',
                },
                {
                    title: 'Операция. ЫЫЫЫЫЫЫЫЫЫ',
                    value: 'Значение неизветсного поля',
                },
                {
                    title: 'Операция. цвайцвйцв',
                    value: 'Значение неизветсного поля',
                },
            ],
        },
        {
            id: 2,
            operationType: 'intragastric-ballon',
            date: '26.06.2024',
            files: [],
            operationFields: [
                // gastric-data-test
                // {
                //     name: 'location-gastroenteroanastomosis',
                //     value: 'Впередиободочное',
                // },
                {
                    name: 'ballon-type',
                    value: 'БАЛОН ЫЫЫЫ',
                },
                {
                    name: 'fullness-of-the-balloon',
                    value: 'Наркточискей раствор',
                },
                {
                    name: 'operation-additional',
                    value: 'ДОП ИНФО',
                },
                {
                    name: 'type-of-operation',
                    value: 'Плановый второй этап',
                },
                {
                    name: 'type-of-bandage',
                    value: 'Allergan AP large',
                },
                {
                    name: 'dissection',
                    value: 'Pars flaccida',
                },
                {
                    name: 'date-adjustment-bandage',
                    value: '11.11.1111',
                },
                {
                    name: 'hemming-stomach-wall',
                    value: 'Да',
                },
                // - - -
                {
                    name: 'drainage-of-the-abdominal-cavity',
                    value: 'Нет',
                },
                {
                    name: 'hemostasis-from-staplers',
                    value: 'Гемостаз биполяром',
                },
                {
                    name: 'thickness-drainage-tube',
                    value: '15 Fr',
                },
                {
                    name: 'suturing-laparotomy-wound',
                    value: 'Узловым способом',
                },
                // ---
                {
                    name: 'weight-operation',
                    value: '100',
                },
                {
                    name: 'date-operation',
                    value: '26.06.2024',
                },
                {
                    name: 'surgeon',
                    value: 'ТЕСТОВЫЙ ТЕСТ ТЕСТОВИЧ',
                },
                {
                    name: 'assistants',
                    value: 'Анастасьевна Анастасия Петрова',
                },
                {
                    name: 'rhabdomyolysis',
                    value: 'Рабдомиолиз',
                },
                // {
                //     name: 'formation-GEA',
                //     value: 'Линейным степлером 45 мм',
                // },
                {
                    name: 'method-determining-place-gastroenteroanastomosis',
                    value: 'Отступ от связки Трейтца',
                },
                // emergency
                {
                    name: 'emergency-situations',
                    value: true,
                },
                {
                    name: 'emergency-situation2',
                    value: 'Да',
                },
                {
                    name: 'discharge-where',
                    value: 'Уехад домоймой',
                },
                // {
                //     name: 'spur-been-formed',
                //     value: 'Да',
                // },
            ],
            observations: [
                {
                    txt: '5 мес.',
                    id: 1,
                    files: [
                        { id: 1, name: 'Картинка1', link: 'https://animatorbest.ru/wp-content/uploads/2019/05/Animator-Spanch-Bob.jpg', type: 'png' },
                        { id: 2, name: 'Документ1', link: 'http://222', type: 'txt' },
                    ],
                    fields: [
                        // {
                        //     name: 'adjusting-bandage',
                        //     value: 'Да',
                        // },
                        {
                            name: 'gerd',
                            value: 'Нет симптомов',
                        },
                        {
                            name: 'compensation-SD',
                            value: 'более 6 недель',
                        },
                        {
                            name: 'date-observation',
                            value: '11.11.1111',
                        },
                        {
                            name: 'weight-observation',
                            value: '111111',
                        },
                        {
                            name: 'EWL',
                            title: '%EWL',
                            value: 1111,
                        },
                    ],
                    outdatedData: [
                        {
                            title: 'Неизвестное поле',
                            value: 'Значение неизветсного поля',
                        },
                        {
                            title: 'Неизвестное поле',
                            value: 'Значение неизветсного поля',
                        },
                        {
                            title: 'Неизвестное поле',
                            value: 'Значение неизветсного поля',
                        },
                    ],
                },
                {
                    txt: '10 мес.',
                    id: 2,
                    files: [
                        { id: 3, name: 'Картинка', link: 'http://2e2', type: 'png' },
                        { id: 4, name: 'Текстовый док', link: 'http://2e2', type: 'txt' },
                    ],
                    fields: [
                        {
                            name: 'adjusting-bandage',
                            value: 'Да',
                        },
                        {
                            name: 'date-observation',
                            value: '22.22.2222',
                        },
                        {
                            name: 'reason-adjusting-bandage',
                            value: 'Дисфагия',
                        },
                    ],
                    outdatedData: [
                        {
                            title: 'Неизвестное поле',
                            value: 'Значение неизветсного поля',
                        },
                        {
                            title: 'Неизвестное поле',
                            value: 'Значение неизветсного поля',
                        },
                        {
                            title: 'Неизвестное поле',
                            value: 'Значение неизветсного поля',
                        },
                    ],
                },
            ],
            outdatedData: [],
        },
        {
            id: 3,
            operationType: 'sasi',
            date: '02.08.2020',
            operationFields: [
                {
                    name: 'weight-operation',
                    value: '130',
                },
            ],
            files: [
                { id: 122, name: 'Тестовый файл 1', link: 'https://images.wallpaperscraft.ru/image/single/ptitsa_siluet_vektor_134154_1920x1080.jpg', type: 'png' },
                { id: 222, name: 'Тестовый файл 2', link: 'https://media.mythopedia', type: 'docx' },
            ],
            observations: [
                {
                    txt: '10 мес.',
                    id: 1,
                    files: [
                        // 'https://media.mythopedia.com/4COrNhCXTVXcd4evDlaf39/769597f2d641be803f9dfa7b735b12cf/greek-gods-name-generator.jpg?w=640&ar=3:2&fit=crop&crop=top&q=50&auto=compress,format%20640w'
                        { id: 122, name: 'Тестовый файл 1', link: 'https://images.wallpaperscraft.ru/image/single/ptitsa_siluet_vektor_134154_1920x1080.jpg', type: 'png' },
                        { id: 222, name: 'Тестовый файл 2', link: 'https://media.mythopedia', type: 'docx' },
                    ],
                    fields: [
                        {
                            name: 'depression',
                            value: 'Есть',
                        },
                        {
                            name: 'adjusting-bandage',
                            value: 'Да',
                        },
                        {
                            name: 'date-observation',
                            value: '27.06.2024',
                        },
                        {
                            name: 'reason-adjusting-bandage',
                            value: 'Желудочек',
                        },
                        {
                            name: 'intake-of-vitamins',
                            value: 'Да',
                        },
                        {
                            name: 'diabetes-mellitus-2-type',
                            value: 'Прежние дозы пероральных препаратов',
                        },
                        {
                            name: 'signs-of-nutritional-deficiency',
                            value: 'Есть',
                        },
                    ],
                    outdatedData: [
                        // {
                        //     title: 'Наблюдение 1 (Неизвестное поле 1)',
                        //     value: 'Значение неизветсного поля (1)',
                        // },
                        // {
                        //     title: 'Наблюдение 1 (Неизвестное поле 2)',
                        //     value: 'Значение неизветсного поля (2)',
                        // },
                        // {
                        //     title: 'Наблюдение 1 (Неизвестное поле 3)',
                        //     value: 'Значение неизветсного поля (3)',
                        // },
                    ],
                },
                {
                    txt: '33 мес.',
                    id: 2,
                    files: [
                        { id: 1212, name: '', link: '', type: '' },
                        { id: 9493, name: '', link: '', type: '' },
                    ],
                    fields: [
                        {
                            name: 'date-observation',
                            value: '22.22.2222',
                        },
                        {
                            name: 'reason-adjusting-bandage',
                            value: 'Желудочек',
                        },
                    ],
                    outdatedData: [
                        {
                            title: 'Наблюдение 2 (Неизвестное поле 1)',
                            value: 'Значение неизветсного поля (1)',
                        },
                        {
                            title: 'Наблюдение 2 (Неизвестное поле 2)',
                            value: 'Значение неизветсного поля (2)',
                        },
                        {
                            title: 'Наблюдение 2 (Неизвестное поле 3)',
                            value: 'Значение неизветсного поля (3)',
                        },
                    ],
                },
            ],
            complications: [
                {
                    fields: [
                        {
                            name: 'date-of-detection-complication',
                            value: '15.06.2024',
                        },
                        {
                            name: 'main-complication',
                            value: 'ОСЛОЖНЁН',
                        },
                        {
                            name: 'localization-complication',
                            value: 'Из гастроэнтероанастомоза',
                        },
                        {
                            name: 'type-of-complication',
                            value: 'Степлерной линии',
                        },
                        {
                            name: 'by-Clavien-Dindo-complication',
                            value: 'I степень - разрешилось без терапии',
                        },
                        {
                            name: 'note-complication',
                            value: 'Примечание к осложнению',
                        },
                    ],
                    interventions: [
                        {
                            fields: [
                                {
                                    name: 'date-repeated-operation',
                                    value: '10.06.2024',
                                },
                                {
                                    name: 'hours-from-main-operation',
                                    value: '12',
                                },
                                {
                                    name: 'method-repeated-operation',
                                    value: 'Конверсионная лапаротомия - Лапароскопия',
                                },
                                {
                                    name: 'type-repeated-operation',
                                    value: 'Остановка кровотечения',
                                },
                                {
                                    name: 'note-intervention',
                                    value: 'Всё в порядке',
                                },
                            ],
                        },
                    ],
                },
                {
                    fields: [
                        {
                            name: 'date-of-detection-complication',
                            value: '11.11.1911',
                        },
                        {
                            name: 'main-complication',
                            value: 'Несостоятельность',
                        },
                        {
                            name: 'localization-complication',
                            value: 'Из гастроэнтероанастомоза',
                        },
                        {
                            name: 'type-of-complication',
                            value: 'Степлерной линии',
                        },
                        {
                            name: 'by-Clavien-Dindo-complication',
                            value: 'IVa степень - интенсивная терапия (полиорганная недостаточность)',
                        },
                    ],
                    interventions: [
                        {
                            fields: [
                                {
                                    name: 'date-repeated-operation',
                                    value: '12.12.2012',
                                },
                                {
                                    name: 'hours-from-main-operation',
                                    value: '12',
                                },
                                {
                                    name: 'method-repeated-operation',
                                    value: 'Конверсионная лапаротомия - Лапароскопия',
                                },
                                {
                                    name: 'type-repeated-operation',
                                    value: 'Остановка кровотечения',
                                },
                                {
                                    name: 'note-intervention',
                                    value: 'Всё в порядке',
                                },
                            ],
                        },
                    ],
                },
            ],
            outdatedData: [
                {
                    title: 'Операция. Неизвестное поле',
                    value: 'Значение неизветсного поля',
                },
                {
                    title: 'Операция. ЫЫЫЫЫЫЫЫЫЫ',
                    value: 'Значение неизветсного поля',
                },
                {
                    title: 'Операция. цвайцвйцв',
                    value: 'Значение неизветсного поля',
                },
            ],
        },
    ];
    window.PATIENT_DATA = {
        // modeTypeOperation: 1 | 2 | 3
        modeTypeOperation: 1,
        gender: 'Женский',
        id: 1161,
        files: [
            {
                id: 1107192,
                name: 'ЕН_ТАЛКО.pdf',
                link: 'https://dev.bareoreg.ru/uploads/patients/43732/IMG_3660.jpeg',
                type: 'jpeg',
            },
            {
                id: 1107193,
                name: 'current-sum (2).pdf',
                link: '/download/current-sum (2).pdf',
                type: 'pdf',
            },
        ],
        fields: [
            {
                name: 'gender',
                value: 'Женский',
            },
            {
                name: 'types-operations-in-anamnesis',
                value: 'Да',
            },
            // {
            //     name: 'goal-is-fertility',
            //     value: 'Нет',
            // },
            // {
            //     name: 'menstrual-function',
            //     value: 'Гиперменорея',
            // },
            // {
            //     name: 'polycystic-ovary',
            //     value: 'Нет',
            // },
            {
                name: 'previous-bariatric-surgery',
                value: 'Внутрижелудочный баллон|Гастропликация|MGB-OAGB (Минигастрошунтирование)',
            },
            {
                name: 'regular-medication-intake',
                value: 'От давления|От сахарного диабета 2-го типа|НПВС/Обезболивающие',
            },
            {
                name: 'ASA-scale',
                value: 'ASA IV (тяжелое заболевание, представляющее постоянную угрозу жизни)',
            },
            {
                name: 'surname',
                value: 'Король',
            },
            {
                name: 'name',
                value: 'Евгений',
            },
            {
                name: 'middle-name',
                value: 'Владимирович',
            },
            {
                name: 'date-of-birth',
                value: '1979-02-14',
            },
            {
                name: 'weight',
                value: 132,
            },
            {
                name: 'height',
                value: 171,
            },
            {
                name: 'patient-status',
                value: 'Наблюдается',
            },
            {
                name: 'address',
                value: 'РФ, МО, г.Москва, 129594, ул.3Марьиной рощи, д.19, кв.76',
            },
            {
                name: 'phone',
                value: '288-61 93',
            },
            {
                name: 'diabetes-mellitus-2-type-patient',
                value: 'Прием пероральных гипогликемических препаратов',
            },
            {
                name: 'duration-diabetes-mellitus',
                value: '-',
            },
            {
                name: 'arterial-hypertension-patient',
                value: 'Нет',
            },
            {
                name: 'dyslipidemia-patient',
                value: 'Да',
            },
            {
                name: 'atherosclerosis',
                value: 'Нет',
            },
            {
                name: 'bronchial-asthma-patient',
                value: 'Нет',
            },
            {
                name: 'functional-status-patient',
                value: '1 этаж без отдыха',
            },
            {
                name: 'pe-risk-factors',
                value: 'Нет',
            },
            {
                name: 'back-and-limb-pain-patient',
                value: 'Нет симптомов',
            },
            {
                name: 'gerd-patient',
                value: 'Периодически возникающие симптомы, купируются без приема препаратов',
            },
            {
                name: 'liver-diseases',
                value: 'Нет данных за заболевания печени',
            },
            {
                name: 'polycystic-ovary',
                value: '-',
            },
            {
                name: 'menstrual-function',
                value: '-',
            },
            {
                name: 'depression',
                value: 'Да',
            },
            {
                name: 'fat-apron',
                value: 'Значительная кожно-жировая складка',
            },
            {
                name: 'smoking',
                value: 'Бывший курильщик',
            },
            {
                name: 'weight-loss-attempts',
                value: 'Диета',
            },
            {
                name: 'urinary-incontinence-patient',
                value: 'Нет',
            },
            {
                name: 'email',
                value: 'extreme@mail.ru',
            },
            // {
            //     name: 'permission_for_videomaterial',
            //     value: 'Да',
            // },
            {
                name: 'who-directed',
                value: 'Интернет',
            },
        ],
        outdatedData: [
            // {
            //     title: 'Неизвестное поле',
            //     value: 'Значение неизветсного поля 1',
            // },
            // {
            //     title: 'Неизвестное поле',
            //     value: 'Значение неизветсного поля 2',
            // },
            // {
            //     title: 'Неизвестное поле',
            //     value: 'Значение неизветсного поля 3',
            // },
            // {
            //     title: 'Неизвестное поле',
            //     value: 'Значение неизветсного поля 4',
            // },
            // {
            //     title: 'Неизвестное поле',
            //     value: 'Значение неизветсного поля 5',
            // },
        ],
    };
}
