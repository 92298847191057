export function checkUnexpectedValue(elementType, elementData) {
    const { options, value, disabledOptions = [] } = elementData;
    let option = '';

    if (!value) return;

    switch (elementType) {
        case 'SELECT':
            if (elementData.multiple) {
                const arrValues = value.split('|');
                arrValues.forEach((element) => {
                    const matchingOption = options.find((option) => option[option.length - 1] === element);
                    if (!matchingOption) {
                        options.push([element, element]);
                        // elementData.disabledOptions = [...disabledOptions, options.length - 1];
                    }
                });
                return;
            }

            option = options.find((el) => el[0] === value);
            if (!option) {
                options.push([value, value]);
                elementData.disabledOptions = [...disabledOptions, options.length - 1];
            }
            break;
        case 'RADIO-GROUP':
            option = options.find((el) => el[0] === value);
            if (!option) {
                options.push([value, value]);
                elementData.disabledOptions = [...disabledOptions, options.length - 1];
            }
            break;
    }
}
