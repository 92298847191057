document.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('click', (event) => {
        const clickedPill = event.target.closest('.pill');
        if (clickedPill) {
            const openPills = document.querySelectorAll('.pill.is-active');
            openPills.forEach((pill) => {
                pill.classList.remove('is-active');
            });
            openPill(clickedPill);
        } else {
            const openPills = document.querySelectorAll('.pill.is-active');
            openPills.forEach((pill) => {
                pill.classList.remove('is-active');
            });
        }
    });

    function openPill(pill) {
        const windowWidth = document.documentElement.clientWidth;
        const pillInner = pill.querySelector('.pill-inner');
        const pillInnerCoordinate = pillInner.getBoundingClientRect();

        if (windowWidth - pillInnerCoordinate.right < 0) {
            pillInner.style.left = 'auto';
            pillInner.style.right = 0;
        }

        pill.classList.add('is-active');
    }
});
