import { assignInputRules, setMasks } from '../../js/input-validate';
import { sendFormData } from '../../js/validate';

const RULES = {
    email: {
        required: {
            message: 'Обязательное поле',
        },
        email: {
            message: 'Некорректный адрес электронной почты',
        },
    },
    password: {
        required: {
            message: 'Обязательное поле',
        },
    },
    // 'password-confirmation': {
    //     required: {
    //         message: 'Обязательное поле',
    //     },
    // },
    surname: {
        required: {
            message: 'Обязательное поле',
        },
    },
    name: {
        required: {
            message: 'Обязательное поле',
        },
    },
    'middle-name': {
        required: {
            message: 'Обязательное поле',
        },
    },
    'medical-institution': {
        required: {
            message: 'Обязательное поле',
        },
    },
    city: {
        required: {
            message: 'Обязательное поле',
        },
    },
    phone: {
        required: {
            message: 'Обязательное поле',
        },
        range: {
            min: 16,
            max: 16,
            message: 'Номер телефона слишком короткий',
        },
    },
};

const wrapperElement = document.querySelector('.wrapper');

if (wrapperElement.classList.contains('login') || wrapperElement.classList.contains('register') || wrapperElement.classList.contains('recovery')) {
    setFieldsListener();
    setMasks();
}

function setFieldsListener() {
    const FORM = document.querySelector('form');

    if (!FORM) return;

    const submitBtn = FORM.querySelector('.submit-button');
    const requireFields = [...FORM.querySelectorAll('[data-required]')];

    requireFields.forEach((field) =>
        field.addEventListener('input', () => {
            // console.log(el.classList.contains('is-invalid') || !el.value);

            setTimeout(() => {
                if (field.name === 'password-confirmation' || field.name === 'password') {
                    const passwordInput = requireFields.find((el) => el.name === 'password');
                    const confirmationPasswordInput = requireFields.find((el) => el.name === 'password-confirmation');

                    if (confirmationPasswordInput) {
                        if (confirmationPasswordInput.value !== passwordInput.value) {
                            const inputWrapper = confirmationPasswordInput.closest('.input-custom');
                            const error = inputWrapper.querySelector('.input-custom__message');
                            error.innerText = 'Пароли не совпадают';
                            error.classList.add('error-message');

                            confirmationPasswordInput.classList.add('is-invalid');
                        } else {
                            confirmationPasswordInput.classList.remove('is-invalid');
                        }
                    }
                }

                const invalidElement = requireFields.find((el) => {
                    if (el.classList.contains('is-invalid') || !el.value) {
                        return true;
                    }
                });

                if (invalidElement) {
                    submitBtn.setAttribute('disabled', true);
                } else {
                    submitBtn.removeAttribute('disabled');
                }
            }, 0);
        })
    );

    if (FORM) {
        FORM.addEventListener('submit', async (e) => {
            e.preventDefault();

            wrapperElement.querySelectorAll('input').forEach((el) => el.classList.remove('is-invalid'));

            const result = await sendFormData(FORM);

            if (result && result.success) {
                const successBlock = document.querySelector('.success-block');

                if (wrapperElement.classList.contains('login')) {
                    location.href = result.data.link;
                } else if (wrapperElement.classList.contains('register')) {
                    wrapperElement.classList.add('is-success');
                } else if (wrapperElement.classList.contains('recovery')) {
                    const email = wrapperElement.querySelector('#email').value;
                    successBlock.querySelector('.success__subtitle').innerText = email;
                    wrapperElement.classList.add('is-success');
                }
            } else if (result && !result.success) {
                result.error.forEach((el) => {
                    const field = document.querySelector(`#${el.fieldName}`);
                    const fieldwrapper = field?.closest('.input-custom');
                    const message = fieldwrapper.querySelector('.input-custom__message');
                    message.innerText = el.text;
                    message?.classList.add('error-message');
                    field?.classList.add('is-invalid');
                });
            }
        });
    }
}

assignInputRules(RULES);
