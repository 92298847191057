import { createAditionalGroup, createGroup, checkConnectionValue, CONNECTED_RULES } from '../form-creating-operation/form-creating-operation';
import { PATIENT_RULES } from '../form-new-patient/form-new-patient';
import { getOperationRules, defineNameOperation } from '../../js/operation-data';
import { OBSERVATION_RULES } from '../form-creating-observation/form-creating-observation';
import { FileLoader } from '../../components/file-loader/file-loader';
import { Complication } from '../complications/complication';
import { assignInputRules } from '../../js/input-validate';
import { setMasks } from '../../js/input-validate';
import { initGroupObserve } from '../../js/validate';
import { createOperationTemplate } from './operation-template';
import { createPatientTemplate } from './patient-template';
import { hightlightRequiredFields } from '../../js/utils/create-group';
import { sendFormData } from '../../js/validate';
import { complicationKeys, complAndInterKeys, interventionKeys } from './operation-template';
import { observeForComplications } from '../../js/validate';
import { printModal } from '../../js/global-scripts';
import { setChangedData } from './changing-data';
import { initCalculateLengthLoops } from '../form-creating-operation/form-creating-operation';
import { setDateLimit } from '../../js/utils/setDateLimit';
import tippy from 'tippy.js';

document.addEventListener('DOMContentLoaded', () => {
    if (!document.querySelector('.wrapper').classList.contains('patient-card')) return;

    // OPERATION_DATA is not defined
    let OPERATIONS = window.OPERATION_DATA?.map((operation) => createOperationTemplate(operation));

    function updateOperations() {
        OPERATIONS = window.OPERATION_DATA?.map((operation) => createOperationTemplate(operation));
    }

    function showRequiredFields(node) {
        const elements = node.querySelectorAll('[data-required]');

        const filteredElements = [...elements].filter((el) => {
            if (el.hasAttribute('data-connected') && el.classList.contains('is-active')) {
                return true;
            } else if (el.hasAttribute('data-connected')) {
                return false;
            } else {
                return true;
            }
        });

        const invalidElements = filteredElements
            .map((element) => {
                if (element.nodeName == 'INPUT') {
                    if (!element.value) {
                        element.classList.add('is-invalid');
                        return element;
                    }
                } else if (element.classList.contains('itc-select')) {
                    if (!element.querySelector('button').getAttribute('value')) {
                        element.classList.add('is-invalid');
                        return element;
                    }
                } else if (element.classList.contains('group-radio-buttons')) {
                    if (!element.classList.contains('is-filled')) {
                        element.classList.add('is-invalid');
                        return element;
                    }
                }
            })
            .filter(Boolean);

        if (invalidElements.length) {
            invalidElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            const groups = document.querySelectorAll('.group:not(.is-filled)');
            groups.length && groups[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    // modal
    const sideModal = document.querySelector('.side-modal');
    const btnSubmit = sideModal.querySelector('.submit-button');
    const btnClose = sideModal.querySelector('.side-modal__close');
    const btnShowRequired = sideModal.querySelector('.side-modal__show-required');
    const btnPrint = sideModal.querySelector('.side-modal__print');

    // service input
    const inputAction = sideModal.querySelector('input[name="action"]');
    const trashBtn = sideModal.querySelector('.side-modal__remove');
    const main = sideModal.querySelector('.side-modal__main');
    const operationFileLoader = new FileLoader({ name: 'operation-fileloader', type: 'loader', requestData: { url: `${location.origin}/personal/patient/${window.PATIENT_DATA.id}/file`, type: 'operation', callBack: fileCallback } });
    const obsLoader = new FileLoader({ name: 'observation-fileloader', type: 'loader', requestData: { url: `${location.origin}/personal/patient/${window.PATIENT_DATA.id}/file`, type: 'observation', callBack: fileCallback } });
    const patientLoader = new FileLoader({ name: 'patient-files', type: 'loader', requestData: { url: `${location.origin}/personal/patient/${window.PATIENT_DATA.id}/file`, type: 'patient', callBack: fileCallback }, duplicate: { target: '.patient-card__files' } });

    patientLoader.createDropZone({ name: 'patient-file-zone' });
    patientLoader.dropZone.classList.add('long');
    let paperclipBtn = document.querySelector('.side-modal__add-file');

    const operationFileLoaderTrigger = operationFileLoader.triggerLoader.bind(operationFileLoader);
    const obsLoaderTrigger = obsLoader.triggerLoader.bind(obsLoader);
    const patientLoaderTrigger = patientLoader.triggerLoader.bind(patientLoader);

    // ПЕЧАТЬ
    document.querySelector('#print-card').addEventListener('click', () => {
        const namePatient = document.querySelector('.general-info__name').innerText;
        document.title = `Карта пациента_${namePatient.replace(/\s/g, '_')}`;
        window.print();
        document.title = ' Карта пациента';
    });
    btnPrint.addEventListener('click', printModal);

    // ПОКАЗАТЬ ОБЯЗАТЕЛЬНЫЕ ПОЛЯ
    btnShowRequired.addEventListener('click', () => showRequiredFields(main));

    // ADD COMPLICATION
    // Копка "Добавить осложнение" внутри модального окна
    const buttonAddComplication = createComplicationBtn();

    const BUTTON_PLUS_COMPLICATION = document.querySelector('.patient-card__add-complication');
    const BUTTON_PLUS_DISEASES = document.querySelector('.patient-card__add-diseases');
    const BUTTON_PLUS_ANAMNESIS = document.querySelector('.patient-card__add-anamnesis');

    if (window.OPERATION_DATA[window.OPERATION_DATA.length - 1]) {
        BUTTON_PLUS_COMPLICATION.dataset.operationId = window.OPERATION_DATA[window.OPERATION_DATA.length - 1]?.id;
        BUTTON_PLUS_COMPLICATION.dataset.modal = 'side-modal';
        BUTTON_PLUS_COMPLICATION.dataset.modalName = 'operation';
    }

    BUTTON_PLUS_DISEASES.dataset.modal = 'side-modal';
    BUTTON_PLUS_DISEASES.dataset.modalName = 'patient';

    BUTTON_PLUS_ANAMNESIS.dataset.modal = 'side-modal';
    BUTTON_PLUS_ANAMNESIS.dataset.modalName = 'patient';

    async function handleClickPlusComplication() {
        if (!this.dataset.operationId) return;
        await new Promise(function (resolve) {
            setTimeout(() => {
                enableEditMode('operation');
            }, 0);
            setTimeout(resolve, 500);
        });

        main.lastChild.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    function modalScrollTo(classBlock) {
        setTimeout(() => main.querySelector(`.${classBlock}`).scrollIntoView({ block: 'start', behavior: 'smooth' }), 500);
    }

    BUTTON_PLUS_COMPLICATION.addEventListener('click', handleClickPlusComplication);
    BUTTON_PLUS_DISEASES.addEventListener('click', () => modalScrollTo('concomitant-diseases'));
    BUTTON_PLUS_ANAMNESIS.addEventListener('click', () => modalScrollTo('anamnesis'));

    // FILES
    const addFileBtn = document.querySelector('.patient-card__add-file');
    addFileBtn?.addEventListener('click', patientLoaderTrigger);

    const files = window.PATIENT_DATA ? window.PATIENT_DATA.files : [];
    files.forEach((file) => patientLoader.createFileTemplate(file));

    function fileCallback(file, action, type) {
        if (type === 'patient') {
            if (action === 'add') {
                window.PATIENT_DATA.files.push(file);
            } else if (action === 'delete') {
                window.PATIENT_DATA.files = window.PATIENT_DATA.files.filter((el) => el.id !== file.id);
            }
        } else if (type === 'operation') {
            const operationId = Number(sideModal.dataset.operationId);
            const operation = window.OPERATION_DATA.find((el) => el.id === operationId);
            if (action === 'add') {
                operation.files.push(file);
            } else if (action === 'delete') {
                operation.files = operation.files.filter((el) => el.id !== file.id);
            }
        } else {
            const operationId = Number(sideModal.dataset.operationId);
            const observationId = Number(sideModal.dataset.observationId);
            const operation = window.OPERATION_DATA.find((el) => el.id === operationId);
            const observation = operation?.observations.find((el) => el.id === observationId);
            if (action === 'add') {
                if (observation) {
                    observation.files.push(file);
                }
            } else if (action === 'delete') {
                if (observation) {
                    observation.files = observation.files.filter((el) => el.id !== file.id);
                }
            }
            updateOperations();
        }
    }

    // ОТПРАВКА ФОРМЫ
    const FORM = sideModal.querySelector('form');

    async function saveForm() {
        const modalName = sideModal.dataset.sideModalName;
        let url;
        btnSubmit.setAttribute('disabled', true);

        switch (modalName) {
            case 'operation':
                url = `${location.origin}/personal/operation/${sideModal.dataset.operationId}/edit`;
                break;
            case 'observation':
                url = `${location.origin}/personal/observation/${sideModal.dataset.observationId}/edit`;
                break;
            case 'patient':
                url = `${location.origin}/personal/patient/${window.PATIENT_DATA.id}/edit`;
                break;
        }

        FORM.setAttribute('action', url);
        let isSuccess = await sendFormData(FORM, null, updateFields);

        btnSubmit.removeAttribute('disabled');

        if (isSuccess) {
            setChangedData(sideModal);
            window.closeModal();
            location.reload();
        }
    }

    FORM.addEventListener('submit', (e) => {
        e.preventDefault();
        saveForm();
    });

    // Функция для обновления данных.
    function updateFields(formData) {
        const modalName = sideModal.dataset.sideModalName;
        const operationId = Number(sideModal.dataset.operationId);
        const observationId = Number(sideModal.dataset.observationId);
        const allComplicationsFields = [];

        const operation = window.OPERATION_DATA.find((el) => el.id === operationId);
        const patient = window.PATIENT_DATA;

        switch (modalName) {
            case 'operation':
                for (const [key, value] of formData) {
                    const currentField = operation.operationFields.find((el) => el.name === key);
                    if (currentField) {
                        currentField.value = value;
                    } else {
                        operation.operationFields.push({ name: key, value: value });
                    }

                    // Осложнения ещё добавить нужно - ЭТО ВЫПОЛНЕНО.
                    // ДОБАВИТЬ ЛОГИКУ ПОВТОРНЫХ ВМЕШАТЕЛЬСТВ - ЭТО ВЫПОЛНЕНО.
                    complAndInterKeys.forEach((name) => {
                        if (key.includes(name)) {
                            allComplicationsFields.push([key, value]);
                        }
                    });
                }

                operation.complications?.forEach((complication, complicationIndex) => {
                    const complicationNumber = complicationIndex + 1;
                    // ОБНОВЛЕНИЕ ОСЛОЖНЕНИЙ
                    complicationKeys.forEach((name) => {
                        const result = allComplicationsFields.find((el) => el[0] === `${name}${complicationNumber}`);
                        if (result) {
                            // Меняем value
                            const object = complication.fields.find((el) => el.name === name);
                            if (object) {
                                object.value = result[1];
                            } else {
                                complication.fields.push({ name: name, value: result[1] });
                            }
                        } else {
                            complication.fields = complication.fields.filter((obj) => obj.name !== name);
                        }
                    });

                    // ОБНОВЛЕНИЕ ПОВТОРНЫХ ВМЕШАТЕЛЬСТВ
                    if (complication.interventions) {
                        complication.interventions.forEach((intervention, interventionIndex) => {
                            const interventionNumber = interventionIndex + 1;
                            interventionKeys.forEach((name) => {
                                const result = allComplicationsFields.find((el) => el[0] === `${name}_complication-${complicationNumber}_intervention-${interventionNumber}`);

                                if (result) {
                                    // Меняем value
                                    const object = intervention.fields.find((el) => el.name === name);
                                    if (object) {
                                        object.value = result[1];
                                    } else {
                                        intervention.fields.push({ name: name, value: result[1] });
                                    }
                                } else {
                                    intervention.fields = intervention.fields.filter((obj) => obj.name !== name);
                                }
                            });
                        });
                    }
                });
                operation.complications = operation.complications.filter((complication) => complication.fields.length > 0);
                break;
            case 'observation':
                // Наблюдения ещё добавить нужно
                const currentObservation = operation.observations.find((observ) => observ.id === observationId);

                if (currentObservation) {
                    for (const [key, value] of formData) {
                        const currentField = currentObservation.fields.find((el) => el.name === key);
                        if (currentField) {
                            currentField.value = value;
                        } else {
                            currentObservation.fields.push({ name: key, value: value });
                        }
                    }
                }
                break;
            case 'patient':
                for (const [key, value] of formData) {
                    const currentField = patient.fields.find((el) => el.name === key);
                    if (currentField) {
                        currentField.value = value;
                    } else {
                        patient.fields.push({ name: key, value: value });
                    }
                }
                break;
        }

        updateOperations();
    }

    function rerenderPaperclip(modalName) {
        const paperClone = paperclipBtn.cloneNode(true);
        switch (modalName) {
            case 'operation':
                paperClone.addEventListener('click', operationFileLoaderTrigger);
                break;
            case 'observation':
                paperClone.addEventListener('click', obsLoaderTrigger);
                break;
            case 'patient':
                paperClone.addEventListener('click', patientLoaderTrigger);
                break;
        }
        tippy(paperClone, {
            content: 'Прикрепить файл',
            theme: 'light',
        });
        sideModal.querySelector('.side-modal__header-buttons').replaceChild(paperClone, paperclipBtn);
        paperclipBtn = paperClone;
    }

    document.addEventListener('click', (event) => {
        const element = event.target.closest('[data-modal-name]');
        if (!element) return;
        const modalName = element.dataset.modalName;

        if (modalName !== 'observation' && modalName !== 'operation' && modalName !== 'patient') return;

        resetModalStyles();
        setGeneralInfo(element, modalName);
    });

    function setGeneralInfo(element, modalName) {
        if (!modalName) return;
        sideModal.removeAttribute('data-observation-id');
        sideModal.dataset.sideModalName = modalName;
        inputAction.value = modalName;
        const modalTitle = sideModal.querySelector('.side-modal__title');

        if (modalName === 'operation' || modalName === 'observation') {
            sideModal.classList.add('view-mode');

            const operationId = Number(element.closest('[data-operation-id]').dataset.operationId);
            const operation = OPERATIONS.find((item) => item.id === operationId);

            // if (!operation) return;

            const blockOperationName = sideModal.querySelector('.side-modal__add-txt');

            // Добавляем id операции на модальное окно
            sideModal.dataset.operationId = operationId;

            rerenderPaperclip(modalName);

            switch (modalName) {
                case 'operation':
                    // Настройки модального окна
                    modalTitle.innerText = defineNameOperation(operation.name);
                    trashBtn.setAttribute('data-modal-name', 'remove-operation');
                    trashBtn.setAttribute('data-operation-id', operationId);

                    fillSideModal(modalName, operation);
                    break;
                case 'observation':
                    const observationId = Number(element.closest('[data-observation-id]').dataset.observationId);
                    const observation = operation.observations.find((item) => item.id === observationId);

                    // Настройки модального окна
                    trashBtn.setAttribute('data-modal-name', 'remove-observation');
                    blockOperationName.querySelector('span').innerText = defineNameOperation(operation.name);
                    blockOperationName.dataset.modal = 'side-modal';
                    blockOperationName.dataset.modalName = 'operation';
                    blockOperationName.dataset.operationId = operationId;
                    trashBtn.setAttribute('data-observation-id', observationId);
                    modalTitle.innerText = `Наблюдение: ${observation.txt} после операции`;
                    sideModal.dataset.observationId = observationId;

                    fillSideModal(modalName, observation);
                    break;
            }
        } else if (modalName === 'patient') {
            // patient
            sideModal.removeAttribute('data-operation-id');
            modalTitle.innerText = 'Редактирование карты пациента';
            enableEditMode(modalName);
            cancelBtn.dataset.modalClose = 'data-modal-close';
            // renderFields(modalName);
        }
    }

    const editBtn = sideModal.querySelector('.side-modal__edit');
    const cancelBtn = sideModal.querySelector('.cancel-button');
    editBtn.addEventListener('click', () => enableEditMode(sideModal.dataset.sideModalName));
    cancelBtn.addEventListener('click', disabledEditMode);

    function createDataBlock(data) {
        let element = document.createElement('div');

        if (data.name === 'SUBTITLE') {
            element = document.createElement('div');
            element.classList.add('group__title-subgroup');
            element.innerHTML = `<span>${data.value}</span>`;
        } else {
            element.classList.add('data-block');
            element.classList.add('data-block--invert');
            element.innerHTML = `
            ${data.title ? `<div class="data-block__title">${data.title}</div>` : ''}
            <div class='data-block__value'>${data.value}</div>
          `;
        }

        return element;
    }

    function renderObservations(observationsArr) {
        const observationsBlock = document.createElement('div');
        observationsBlock.classList.add('observations');
        observationsBlock.innerHTML = `
            <div class='observations__title'>Наблюдения ${observationsArr.length}:</div>
            <div class='observations__wrapper'></div>
            `;
        const wrapper = observationsBlock.querySelector('.observations__wrapper');

        const addBtn = document.createElement('a');
        addBtn.setAttribute('href', `${location.origin}/personal/observation/${window.PATIENT_DATA.id}/add`);
        addBtn.classList.add('observations__add');
        addBtn.setAttribute('type', 'button');
        addBtn.innerHTML = `<svg><use href='/img/sprite.svg#plus-icon'></use></svg>`;
        wrapper.append(addBtn);

        function createObservationPill(data) {
            const observationPill = document.createElement('div');
            observationPill.dataset.modalName = 'observation';
            observationPill.dataset.observationId = data.id;
            // observationPill.dataset.operationId = data.id;
            observationPill.classList.add('pill__observation', 'pill__observation--transparent');
            observationPill.dataset.modalName;
            observationPill.innerText = data.txt;

            return observationPill;
        }

        // console.log(observationsArr);
        // console.log(observationsArr.reverse());

        observationsArr.forEach((item) => {
            wrapper.append(createObservationPill(item));
        });

        sideModal.querySelector('.side-modal__main').prepend(observationsBlock);
    }

    function fillSideModal(modalName, data) {
        main.innerHTML = '';

        const operation = OPERATIONS.find((item) => item.id === Number(sideModal.dataset.operationId));

        let content;

        if (modalName === 'operation') {
            data.observations && renderObservations(data.observations);
            main.append(operationFileLoader.fileLoader);
            operationFileLoader.reset();
            data.files.forEach((file) => operationFileLoader.createFileTemplate(file));
            if (data.outdatedData) {
                content = [...data.operation.info, data.outdatedData];
            } else {
                content = data.operation.info;
            }
        } else if (modalName === 'observation') {
            let files;
            main.append(obsLoader.fileLoader);
            const observationId = sideModal.dataset.observationId;
            operation.observations.forEach((item) => {
                if (item.id === Number(observationId)) {
                    files = item.files || [];
                }
            });
            obsLoader.reset();
            files.forEach((file) => obsLoader.createFileTemplate(file));
            if (data.outdatedData) {
                content = [...data.info, data.outdatedData];
            } else {
                content = data.info;
            }
        }

        content.forEach((item) => {
            let dataBlocks;
            let group;

            if (item.dataBlocks && item.dataBlocks.length) {
                dataBlocks = item.dataBlocks.map((block) => createDataBlock(block));
                group = createGroup({ title: item.name, addClass: item.addClass, dataBlocks: dataBlocks });
            } else {
                group = createGroup({ title: item.name, addClass: item.addClass, subtitle: item.subtitle });
            }

            if (item.additionalGroups && item.additionalGroups.length) {
                const additionalGroups = item.additionalGroups.map((data) => {
                    let dataBlocks;
                    if (data.dataBlocks) {
                        dataBlocks = data.dataBlocks.map((block) => createDataBlock(block));
                    }
                    return createAditionalGroup({ ...data, dataBlocks: dataBlocks });
                });

                additionalGroups.forEach((el) => {
                    group.querySelector('.group__inner').append(el);
                });
            }

            main.append(group);
        });
    }

    function enableEditMode(modalName) {
        main.innerHTML = '';
        sideModal.classList.remove('view-mode');
        sideModal.classList.add('is-editable');
        buttonAddComplication.classList.remove('is-active');
        // Запрет закрытия модалки
        sideModal.classList.add('not-close');
        btnClose.removeAttribute('data-modal-close');

        renderFields(modalName);
        if (modalName === 'patient') {
            main.prepend(patientLoader.fileLoader);
            main.querySelector('.group__form .textarea').after(patientLoader.dropZone);
        }

        if (modalName === 'observation') {
            main.prepend(obsLoader.fileLoader);
        }

        if (modalName === 'operation') {
            main.prepend(operationFileLoader.fileLoader);
            buttonAddComplication.classList.add('is-active');
            createComplicationBtn();
        }
    }

    function disabledEditMode() {
        const modalName = sideModal.dataset.sideModalName;
        sideModal.classList.remove('not-close');
        btnClose.setAttribute('data-modal-close', '');

        if (modalName === 'patient') return;

        const operationId = Number(sideModal.dataset.operationId);
        const observationId = Number(sideModal.dataset.observationId);
        const operation = OPERATIONS.find((item) => item.id === operationId);

        // main.innerHTML = '';
        sideModal.classList.add('view-mode');
        sideModal.classList.remove('is-editable');

        if (modalName === 'observation') {
            const observation = operation.observations.find((item) => item.id === observationId);
            fillSideModal(sideModal.dataset.sideModalName, observation);
            paperclipBtn.addEventListener('click', obsLoader.triggerLoader);
        } else {
            fillSideModal(sideModal.dataset.sideModalName, operation);
            paperclipBtn.addEventListener('click', operationFileLoader.triggerLoader);
        }
    }

    function createComplicationBtn() {
        const button = document.createElement('button');
        button.classList.add('btn', 'btn--ghost', 'side-modal__add-complication');
        button.type = 'button';
        button.innerHTML = '<span>Добавить осложнение</span>';
        button.addEventListener('click', () => createComplication());

        sideModal.querySelector('.side-modal__bottom .cancel-button').before(button);

        return button;
    }

    function renderFields(modalName) {
        Complication.resetCounter();
        const operation = OPERATIONS.find((item) => item.id === Number(sideModal.dataset.operationId));
        let content;
        let files;
        let outdatedGroup;

        if (modalName === 'operation') {
            content = operation.operation.fields;
            operationFileLoader.reset();
            operation.files.forEach((file) => operationFileLoader.createFileTemplate(file));
        } else if (modalName === 'observation') {
            const observationId = sideModal.dataset.observationId;
            operation.observations.forEach((item) => {
                if (item.id === Number(observationId)) {
                    content = item.fields;
                    files = item.files || [];
                }
            });
            obsLoader.reset();
            files.forEach((file) => obsLoader.createFileTemplate(file));
            // content = operation.observations.find((item) => item.id === Number(observationId)).fields;
        } else if (modalName === 'patient') {
            patientLoader.reset();
            const PATIENT = createPatientTemplate(window.PATIENT_DATA);
            content = PATIENT.fields;
            files = PATIENT.files || [];
            files.forEach((file) => patientLoader.createFileTemplate(file));

            // УСТАРЕВШИЕ ПОЛЯ
            if (PATIENT.outdatedData) {
                const dataBlocks = PATIENT.outdatedData.dataBlocks.map((block) => createDataBlock(block));
                outdatedGroup = createGroup({ title: PATIENT.outdatedData.name, addClass: PATIENT.outdatedData.addClass, dataBlocks: dataBlocks });
            }
        }

        content.forEach((el) => {
            if (el.complication) {
                const complicationInstance = createComplication(el.data);
                const complicationBlock = complicationInstance.el;
                let connectedEls = complicationBlock.querySelectorAll('[data-connected]');
                connectedEls.forEach((el) => {
                    checkConnectionValue(el, CONNECTED_RULES);
                });

                if (el.interventions && el.interventions.length) {
                    el.interventions.forEach((repeatedInt) => {
                        repeatedInt.location = 'inside-the-modal';
                        complicationInstance.createIntervention(repeatedInt);
                    });
                }
                return;
            }

            let group = createGroup(el);

            if (el.additionalGroups) {
                let additionalGroups = [];
                let groupInner = group.querySelector('.group__inner');
                el.additionalGroups.forEach((addGroup) => {
                    additionalGroups.push(createAditionalGroup(addGroup));
                });
                additionalGroups.forEach((item) => groupInner.append(item));
            }

            main.append(group);

            let connectedEls = group.querySelectorAll('[data-connected]');
            connectedEls.forEach((el) => {
                // console.log(el);
                checkConnectionValue(el, CONNECTED_RULES);
            });
        });

        if (modalName === 'operation') {
            const operationRules = getOperationRules(operation.name);
            // console.log(operationRules);
            assignInputRules(operationRules);
            observeForComplications();
            initCalculateLengthLoops();
        }
        if (modalName === 'patient') {
            // ДОБАВЛЯЕМ УСТАРЕВШИЕ ПОЛЯ В DOM
            if (outdatedGroup) {
                main.append(outdatedGroup);
            }
            assignInputRules(PATIENT_RULES);
        }
        if (modalName === 'observation') {
            assignInputRules(OBSERVATION_RULES);
        }

        setBehaviorForTypeOfOperation();
        initGroupObserve();
        setAdditionalConnection();
        hightlightRequiredFields();
        setDateLimit();
        setMasks();
    }

    // ДОПОЛНИТЕЛЬНАЯ ЛОГИКА СВЯЗЕЙ (is-active)
    // Интероперационных осложнений
    // disabled - для других вариантов.
    function setAdditionalConnection() {
        const hasConnectedEl = document.querySelector('[data-has-connection="no-complications"]');
        if (!hasConnectedEl) return;
        let connectedEls = document.querySelectorAll(`[data-connected="no-complications"]`);
        function checkState() {
            if (hasConnectedEl.checked) {
                connectedEls.forEach((element) => {
                    element.checked = false;
                    element.setAttribute('disabled', 'disabled');
                    element.classList.remove('is-active');
                    document.querySelector(`.group[data-connected=${element.dataset.hasConnection}]`).classList.remove('is-active');
                });
            } else {
                connectedEls.forEach((element) => {
                    element.removeAttribute('disabled', 'disabled');
                    element.classList.add('is-active');
                });
            }
        }

        checkState();
    }

    function createComplication(data) {
        let complication;
        const dateOperationField = document.getElementById('date-operation');

        if (data) {
            complication = new Complication({ addClass: ['group--parent', 'group--simple'], interventionClass: 'group--simple', fieldsValue: data, location: 'inside-the-modal', operationDate: dateOperationField.value });
        } else {
            complication = new Complication({ addClass: ['group--parent', 'group--simple'], interventionClass: 'group--simple', location: 'inside-the-modal', operationDate: dateOperationField.value });
        }

        // Добавляем правила к общим правилам.
        complication.connectionRules.forEach((item) => {
            CONNECTED_RULES[item.name] = item.rules;
        });

        main.append(complication.el);
        if (!data) {
            complication.el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        assignInputRules(complication.fieldsRules);
        initGroupObserve();
        hightlightRequiredFields();

        return complication;
    }

    function resetModalStyles() {
        sideModal.classList.remove('view-mode', 'is-editable');
        cancelBtn.removeAttribute('data-modal-close');
    }

    // ОТКРЫТЬ ТО ЧТО ОТРЕДАКТИРОВАЛИ
    function openPreviousModal() {
        const openModalObj = JSON.parse(localStorage.getItem('openModal'));
        if (!openModalObj) return;
        let element;
        if (openModalObj.modalName === 'operation') {
            const selector = '[data-operation-id=' + `"${openModalObj.operationId}"` + ']';
            const pill = document.querySelector(selector);
            element = pill.querySelector('[data-modal-name="operation"]');
        } else if (openModalObj.modalName === 'observation') {
            const selector = '[data-observation-id=' + `"${openModalObj.observationId}"` + ']';
            element = document.querySelector(selector);
        }
        // else if (openModalObj.modalName === 'patient') {
        //     const selector = '[data-modal-name="patient"';
        //     element = document.querySelector(selector);
        // }
        if (element) element.click();
        localStorage.removeItem('openModal');
    }

    openPreviousModal();

    function setBehaviorForTypeOfOperation() {
        const modeTypeOperation = window.PATIENT_DATA.modeTypeOperation;
        const selectTypeOperation = sideModal.querySelector('#type-of-operation');

        if (!selectTypeOperation) return;

        if (modeTypeOperation) {
            let disableThis;
            switch (modeTypeOperation) {
                case 1:
                    disableThis = selectTypeOperation.querySelectorAll('[data-value="Ревизионная (по поводу неэффективности или осложнений предыдущей операции)"], [data-value="Плановый второй этап"]');
                    disableThis.forEach((el) => el.classList.add('disabled'));
                    break;
                case 2:
                    disableThis = selectTypeOperation.querySelectorAll('[data-value="Первичная"], [data-value="Ревизия после бариатрической операции в другой клинике"]');
                    disableThis.forEach((el) => el.classList.add('disabled'));
                    break;
                case 3:
                    break;
            }
        }

        // Очистка взаимосвязанных элементов
        const buttonTypeOperation = selectTypeOperation.querySelector('button');
        if (buttonTypeOperation) {
            let observer = new MutationObserver(() => {
                const connectedElements = document.querySelectorAll('[data-connected="operation-type"]');
                connectedElements.forEach((el) => {
                    if (!el.classList.contains('is-active') && el.classList.contains('itc-select')) {
                        const button = el.querySelector('button');
                        const input = el.querySelector('input');
                        button.setAttribute('data-index', '-1');
                        button.setAttribute('value', '');
                        button.querySelector('.itc-select__text-selected').innerText = '';
                        input.value = '';
                        const selectedLiArr = el.querySelectorAll('.itc-select__option_selected');
                        if (selectedLiArr.length > 0) {
                            selectedLiArr.forEach((el) => el.classList.remove('itc-select__option_selected'));
                        }
                        setTimeout(() => el.classList.remove('is-invalid'));
                    }
                });
            });
            observer.observe(buttonTypeOperation, { attributeFilter: ['data-index'] });
        }
    }
});
