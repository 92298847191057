import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);
Chart.defaults.font.family = 'TTNormsPro';
Chart.defaults.color = '#49454f';

// window.CHART_POINTS = [
//     { x: '0 мес.', y: 0 },
//     { x: '36 мес.', y: 51.9 },
//     { x: '48 мес.', y: 17.3 },
//     { x: '49 мес.', y: 51.9 },
//     { x: '60 мес.', y: 51.9 },
//     { x: '69 мес.', y: -86.6 },
//     { x: '71 мес.', y: -34.6 },
//     { x: '72 мес.', y: -69.3 },
// ];

// window.CHART_OPERATIONS = [
//     { label: 'MGB-OAGB', x: '0 мес.', y: 0 },
//     { label: 'SADI', x: '36 мес.', y: 51.9 },
//     { label: 'Гастропликация', x: '36 мес.', y: 51.9 },
//     { label: 'БПШ', x: '36 мес.', y: 51.9 },
//     { label: 'БЖ', x: '48 мес.', y: 112.6 },
//     { label: 'SLIM OAGB', x: '48 мес.', y: 17.3 },
//     { label: 'SADI', x: '49 мес.', y: 51.9 },
//     { label: 'ВЖБ', x: '60 мес.', y: 51.9 },
//     { label: 'SLIM OAGB', x: '71 мес.', y: -34.6 },
//     { label: 'ПРЖ', x: '72 мес.', y: 17.3 },
//     { label: 'Гастропликация', x: '72 мес.', y: 112.6 },
//     { label: 'Другая', x: '72 мес.', y: 51.9 },
//     { label: 'Гастропликация', x: '72 мес.', y: 112.6 },
//     { label: 'БПШ', x: '72 мес.', y: 17.3 },
//     { label: 'БЖ', x: '72 мес.', y: -69.3 },
// ];

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    legendContainer.style.width = `${chart.chartArea.width}px`;
    legendContainer.style.margin = `0 auto`;
    // legendContainer.style.paddingBottom = '5px';

    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        // listContainer.style.display = 'flex';
        // listContainer.style.flexDirection = 'row';
        // listContainer.style.width = `${chart.chartArea.width}px`;

        listContainer.style.left = 0;
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;
        listContainer.style.height = '20px';
        listContainer.style.position = 'relative';

        legendContainer.appendChild(listContainer);
    }

    return listContainer;
};

const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);
        ul.style.height = '20px';

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        let items = chart.options.plugins.legend.labels.generateLabels(chart);
        items = items.filter((item) => Boolean(item.text));

        function calcLegendElPosition(item) {
            // const graphWidth = document.querySelector('.chart-wrapper').offsetWidth;
            const graphWidth = chart.chartArea.width;
            const lengthSegment = graphWidth / (window.CHART_POINTS.length - 1);
            const pointOperation = window.CHART_OPERATIONS[item.datasetIndex - 1];
            const point = window.CHART_POINTS.find((el) => el.x === pointOperation.x);
            const pointOrder = window.CHART_POINTS.indexOf(point);

            if (pointOrder === 0) {
                return '0px';
            }

            if (pointOrder === window.CHART_POINTS.length - 1) {
                return '100%';
            }

            return `${pointOrder * lengthSegment}px`;
        }

        const leftValues = [];
        const heightStep = 20;

        items.forEach((item) => {
            const leftPosition = calcLegendElPosition(item);
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'inline';
            li.style.position = 'absolute';
            li.style.left = leftPosition;
            li.style.bottom = '0';

            const samePositionElement = leftValues.find((el) => el.left === leftPosition);
            if (!samePositionElement) {
                leftValues.push({ left: leftPosition, amountElements: 1, bottomPosition: 20 });
            } else {
                ul.style.height = `${ul.offsetHeight + heightStep}px`;
                li.style.bottom = `${samePositionElement.bottomPosition}px`;
                samePositionElement.bottomPosition += heightStep;
                samePositionElement.amountElements += 1;
            }

            // точка операции ==== последняя точка графика
            // const pointOperation = window.CHART_OPERATIONS[item.datasetIndex - 1];
            // if (pointOperation.x === window.CHART_POINTS[window.CHART_POINTS.length - 1].x) {
            //     li.style.transform = 'translateX(-95%)';
            // }

            li.onclick = () => {
                const { type } = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.fontSize = '14px';
            textContainer.style.lineHeight = '20px';
            textContainer.style.fontWeight = 500;
            textContainer.style.whiteSpace = 'nowrap';
            textContainer.style.margin = 0;
            textContainer.style.padding = 0;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    },
};

const myChartCanvas = document.querySelector('#myChart');

let myChart;
let maxY = 0;
let minY = 0;

window.CHART_POINTS?.forEach((point) => {
    if (point.y > maxY) {
        maxY = point.y;
    }
    if (point.y < minY) {
        minY = point.y;
    }
});

maxY = maxY + 20;
minY = minY < 0 ? minY - 20 : minY;

window.CHART_OPERATIONS?.forEach((chart) => {
    const topPoint = { x: chart.x, y: maxY };
    const bottomPoint = { x: chart.x, y: minY - 20 };

    chart.points = [{ x: chart.x, y: chart.y }];

    if (!isAlreadyThere(chart.points, topPoint)) {
        chart.points.push(topPoint);
    }

    if (!isAlreadyThere(chart.points, bottomPoint)) {
        chart.points.unshift(bottomPoint);
    }
});

function isAlreadyThere(arr, point) {
    const foundElement = arr.find((el) => {
        return el.y === point.y && el.x === point.x;
    });

    return Boolean(foundElement);
}

// Ставит две точки в 0. Текущую точку. Плюс точку начала графика.

function createChartOperation(chartOperation) {
    return {
        label: chartOperation.label,
        data: chartOperation.points,
        borderWidth: 2,
        borderDash: [2],
        borderColor: 'rgba(0, 0, 0, 0.40)',
        // Отключить видимость точки, но отсавить тултип
        pointStyle: false,

        // Отключение тултипа и видимости точек
        pointRadius: function (context) {
            let index = context.dataIndex;
            let value = context.dataset.data[index];
            const pointIntersection = window.CHART_POINTS?.find((point) => point.y === value.y);
            if (pointIntersection) {
                return 3;
            } else {
                return 0;
            }
        },
        pointHitRadius: function (context) {
            let index = context.dataIndex;
            let value = context.dataset.data[index];
            const pointIntersection = window.CHART_POINTS?.find((point) => point.y === value.y);
            if (pointIntersection) {
                return 1;
            } else {
                return 0;
            }
        },
        // pointRadius: 0,
        // pointHitRadius: 1,
    };
}

const operations = window.CHART_OPERATIONS?.map((chartOperation) => createChartOperation(chartOperation)) || [];

if (myChartCanvas) {
    myChart = new Chart(myChartCanvas, {
        type: 'line',
        data: {
            datasets: [
                {
                    label: '',
                    data: window.CHART_POINTS || [],
                    fill: {
                        target: 'origin',
                        // 'linear-gradient(180deg, rgb(54, 240, 151) 0%, rgba(54, 240, 151, 0.20) 100%)'

                        above: 'rgba(54, 240, 151, 0.3)', // Area will be green above the origin
                        // below: 'rgb(0, 0, 255)', // And blue below the origin
                    },
                    pointBorderWidth: 6,
                    pointRadius: 3,
                    pointBackgroundColor: '#36F097',
                    pointBorderColor: 'rgba(54, 240, 151, 0.3)',

                    // fill: '#00000',
                    // borderDash: 0.4,
                    borderWidth: 2,
                    borderColor: '#36F097',
                },

                // операции
                ...operations,
            ],
        },
        options: {
            scales: {
                y: {
                    max: maxY,
                    min: minY,
                    beginAtZero: true,
                },
            },
            // animation: false,
            plugins: {
                legend: {
                    display: false,
                    // labels: {
                    //     boxWidth: 0,
                    //     boxHeight: 0,
                    //     // Boolean(item.text),
                    //     filter: (item) => Boolean(item.text),
                    // },
                },
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: 'legend-container',
                },
                tooltip: {
                    // enabled: false,
                    backgroundColor: 'rgba(103, 80, 164, 1)',
                    padding: 10,
                    bodySpacing: 4,
                    boxPadding: 4,
                    titleFont: {
                        family: 'TTNormsPro',
                    },
                    bodyFont: {
                        family: 'TTNormsPro',
                    },
                },
            },
            maintainAspectRatio: false,
            responsive: true,
            // aspectRatio: 3.3,
        },
        plugins: [htmlLegendPlugin],
    });
}
