import { FileLoader } from '../../components/file-loader/file-loader';
import { hightlightRequiredFields } from '../../js/utils/create-group';
import { assignInputRules } from '../../js/input-validate';
import { sendFormData } from '../../js/validate';

// dropZone
const problemFiles = new FileLoader({
    type: 'dropzone',
    name: 'problem-files',
    target: '#problem-files',
});

export const SUPPORT_RULES = {
    'topic-problem': {
        required: {
            message: 'Обязательное поле',
        },
    },
    'description-problem': {
        required: {
            message: 'Обязательное поле',
        },
    },
};

if (document.querySelector('.wrapper').classList.contains('support')) {
    const form = document.querySelector('.support form');
    const fields = form?.querySelectorAll('.input-custom input, .textarea textarea');
    const btnSubmit = form?.querySelector('.submit-button');
    const modalResult = document.querySelector('.modal-result-query');
    fields.forEach((el) => el.addEventListener('input', checkFields));

    function checkFields() {
        const element = [...fields].find((el) => el.hasAttribute('data-required') && !el.value);
        if (element) {
            btnSubmit.setAttribute('disabled', true);
        } else {
            btnSubmit.removeAttribute('disabled');
        }
    }

    // отправка
    if (form) {
        form.addEventListener('submit', async (e) => {
            e.preventDefault();
            const result = await sendFormData(form, problemFiles);
            if (result) {
                modalResult.classList.remove('rejected');
                modalResult.querySelector('.i-modal__title').innerHTML = 'Ваше сообщение успешно доставлено';
            } else {
                modalResult.querySelector('.i-modal__title').innerHTML = 'Что-то пошло не так...<br> Попробуйте ещё раз';
                modalResult.classList.add('rejected');
            }
            window.openModal('modal-result-query');
            setTimeout(() => {
                window.closeModal();
            }, 2000);
        });
    }

    assignInputRules(SUPPORT_RULES);
    hightlightRequiredFields();
}
