export class TooltipActions {
    static OPENER = 'data-open-tooltip';

    static _createTooltip(data) {
        const tooltip = document.createElement('ul');
        tooltip.classList.add('tooltip-menu');
        if (data.id) {
            tooltip.id = data.id;
        }
        data.options.forEach((opt) => {
            const classes = opt.addClass || '';
            if (opt.href) {
                tooltip.insertAdjacentHTML('beforeend', `<a href='${opt.href}'><li class='tooltip-menu__option ${classes}'><svg><use href='/img/sprite.svg#${opt.icon}'></use></svg><span>${opt.txt}</span></li></a>`);
            } else {
                tooltip.insertAdjacentHTML('beforeend', `<li class='tooltip-menu__option ${classes}'><svg><use href='/img/sprite.svg#${opt.icon}'></use></svg><span>${opt.txt}</span></li>`);
            }
        });
        return tooltip;
    }

    constructor(data) {
        this.tooltip = TooltipActions._createTooltip(data);
    }

    static hideTooltip() {
        document.addEventListener('click', (e) => {
            if (!e.target.closest(`.tooltip-menu`) && !e.target.closest('[data-open-tooltip]')) {
                const elsActive = document.querySelectorAll(`.tooltip-menu.is-active`);
                elsActive.forEach((el) => {
                    el.classList.remove('is-active');
                });
            }
        });
    }
}

TooltipActions.hideTooltip();
