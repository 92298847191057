name: 'operation' | 'observation' | 'patient';

export const changedData = {
    modal: 'side-modal',
    modalName: '',
    operationId: null,
    observationId: null,
};

export function setChangedData(modal) {
    const modalName = modal.dataset.sideModalName;
    changedData.modalName = modalName;
    if (modalName === 'operation') {
        changedData.operationId = modal.dataset.operationId;
    } else if (modalName === 'observation') {
        changedData.operationId = modal.dataset.operationId;
        changedData.observationId = modal.dataset.observationId;
    }

    localStorage.setItem('openModal', JSON.stringify(changedData));
}
