const patientTemplate = [
    {
        title: 'Персональные данные пациента',
        addClass: ['group--simple'],
        fields: [
            {
                type: 'INPUT',
                data: {
                    name: 'surname',
                    type: 'text',
                    placeholder: 'Фамилия*',
                    required: true,
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'name',
                    type: 'text',
                    placeholder: 'Имя*',
                    required: true,
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'middle-name',
                    type: 'text',
                    placeholder: 'Отчество*',
                    required: true,
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'date-of-birth',
                    type: 'text',
                    placeholder: 'Дата рождения*',
                    required: true,
                    mod: 'calendar',
                },
            },
            {
                type: 'RADIO-GROUP',
                data: {
                    placeholder: 'Пол*',
                    name: 'gender',
                    options: [
                        ['Мужской', 'Мужской'],
                        ['Женский', 'Женский'],
                    ],
                    hasConnection: 'gender',
                    required: true,
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'weight',
                    type: 'number',
                    placeholder: 'Вес кг*',
                    required: true,
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'height',
                    type: 'number',
                    placeholder: 'Рост (см)*',
                    required: true,
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'patient-status',
                    placeholder: 'Статус',
                    options: [
                        ['Новый пациент', 'Новый пациент'],
                        ['Наблюдается', 'Наблюдается'],
                        ['Выбыл из наблюдения', 'Выбыл из наблюдения'],
                        ['Летальный исход', 'Летальный исход'],
                    ],
                    required: true,
                    hasConnection: 'status',
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'reason-for-disposal',
                    type: 'text',
                    placeholder: 'Причина выбытия*',
                    required: true,
                    addClass: 'long',
                    connected: 'status',
                },
            },
        ],
    },
    {
        title: 'Общая информация и контактные данные',
        addClass: ['group--simple'],
        fields: [
            {
                type: 'SELECT',
                data: {
                    name: 'who-directed',
                    placeholder: 'Кем направлен',
                    options: [
                        ['Самостоятельно', 'Самостоятельно'],
                        ['По рекомендации прооперированных пациентов', 'По рекомендации прооперированных пациентов'],
                        ['По рекомендации смежных специалистов (эндокринолог, терапевт, кардиолог, сомнолог и других)', 'По рекомендации смежных специалистов (эндокринолог, терапевт, кардиолог, сомнолог и других)'],
                    ],
                    required: false,
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'phone',
                    type: 'phone',
                    placeholder: 'Телефон',
                    required: true,
                    attributes: [{ name: 'data-mask', value: 'phone' }],
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'email',
                    type: 'text',
                    placeholder: 'E-mail',
                    required: false,
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'city',
                    type: 'text',
                    placeholder: 'Город',
                    required: false,
                },
            },
            {
                type: 'INPUT',
                data: {
                    name: 'address',
                    type: 'text',
                    placeholder: 'Адрес (с почтовым индексом)',
                    required: false,
                },
            },
            {
                type: 'CHECKBOX',
                data: {
                    name: 'permission_for_videomaterial',
                    value: 'Да',
                    placeholder: 'Получено разрешение на использование <br>фото- и видеоматериалов',
                },
            },
            {
                type: 'CHECKBOX',
                data: {
                    name: 'permission_for_email_mailing',
                    value: 'Да',
                    placeholder: 'Получено разрешение на email-рассылку',
                },
            },
            {
                type: 'CHECKBOX',
                data: {
                    name: 'permission_for_sms_mailing',
                    value: 'Да',
                    placeholder: 'Получено разрешение на sms-рассылку',
                },
            },
        ],
    },
    {
        title: 'Сопутствующие заболевания',
        addClass: ['group--simple', 'concomitant-diseases', 'group--parent'],
        fields: [
            {
                type: 'SELECT',
                data: {
                    name: 'arterial-hypertension-patient',
                    placeholder: 'Артериальная гипертензия',
                    options: [
                        ['Нет', 'Нет'],
                        ['Есть, терапию не получает', 'Есть, терапию не получает'],
                        ['Компенсирована терапией', 'Компенсирована терапией'],
                        ['Не компенсирована терапией', 'Не компенсирована терапией'],
                    ],
                    required: false,
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'diabetes-mellitus-2-type-patient',
                    placeholder: 'Сахарный диабет 2-го типа',
                    options: [
                        ['Нет признаков', 'Нет признаков'],
                        ['Инсулинорезистентность, гипергликемия или нарушение толерантности к глюкозе', 'Инсулинорезистентность, гипергликемия или нарушение толерантности к глюкозе'],
                        ['Прием пероральных гипогликемических препаратов', 'Прием пероральных гипогликемических препаратов'],
                        ['Инсулинотерапия', 'Инсулинотерапия'],
                    ],
                    required: true,
                    hasConnection: 'diabetes',
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'duration-diabetes-mellitus',
                    placeholder: 'Длительность течения СД2',
                    options: [
                        ['Впервые выявлен', 'Впервые выявлен'],
                        ['Менее 1 года', 'Менее 1 года'],
                        ['1 год', '1 год'],
                        ['2 года', '2 года'],
                        ['3 года', '3 года'],
                        ['4 года', '4 года'],
                        ['5 лет', '5 лет'],
                        ['6 лет', '6 лет'],
                        ['7 лет', '7 лет'],
                        ['8 лет', '8 лет'],
                        ['9 лет', '9 лет'],
                        ['10 лет', '10 лет'],
                        ['Более 10 лет', 'Более 10 лет'],
                    ],
                    required: false,
                    connected: 'diabetes',
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'gerd-patient',
                    placeholder: 'ГЭРБ',
                    options: [
                        ['Нет признаков', 'Нет признаков'],
                        ['Периодически возникающие симптомы, купируются самостоятельно', 'Периодически возникающие симптомы, купируются самостоятельно'],
                        ['Периодические возникающие симптомы, купируются препаратами', 'Периодические возникающие симптомы, купируются препаратами'],
                        ['Ежедневный прием препаратов', 'Ежедневный прием препаратов'],
                    ],
                },
            },
            {
                type: 'RADIO-GROUP',
                data: {
                    placeholder: 'Дислипидемия',
                    name: 'dyslipidemia-patient',
                    options: [
                        ['Да', 'Да'],
                        ['Нет', 'Нет'],
                    ],
                    required: false,
                },
            },
            {
                type: 'RADIO-GROUP',
                data: {
                    placeholder: 'Атеросклероз',
                    name: 'atherosclerosis',
                    options: [
                        ['Да', 'Да'],
                        ['Нет', 'Нет'],
                    ],
                    required: false,
                },
            },
            {
                type: 'RADIO-GROUP',
                data: {
                    placeholder: 'Апноэ',
                    name: 'apnea',
                    options: [
                        ['Да', 'Да'],
                        ['Нет', 'Нет'],
                    ],
                    required: false,
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'bronchial-asthma-patient',
                    placeholder: 'Бронхиальная астма',
                    options: [
                        ['Нет', 'Нет'],
                        ['Ингалятор', 'Ингалятор'],
                        ['Небулайзер/пероральные стероиды', 'Небулайзер/пероральные стероиды'],
                    ],
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'functional-status-patient',
                    placeholder: 'Функциональный статус',
                    options: [
                        ['3 этажа без отдыха', '3 этажа без отдыха'],
                        ['1 этаж без отдыха', '1 этаж без отдыха'],
                        ['Половина лестничного пролета', 'Половина лестничного пролета'],
                        ['Не передвигается самостоятельно / Не выходит из дома', 'Не передвигается самостоятельно / Не выходит из дома'],
                    ],
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'back-and-limb-pain-patient',
                    placeholder: 'Боль в спине и конечностях',
                    options: [
                        ['Нет симптомов', 'Нет симптомов'],
                        ['Периодически возникающие симптомы', 'Периодически возникающие симптомы'],
                        ['Регулярный прием препаратов', 'Регулярный прием препаратов'],
                    ],
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'liver-diseases',
                    placeholder: 'Заболевания печени',
                    options: [
                        ['Нет данных', 'Нет данных'],
                        ['Признаки стеатогепатоза', 'Признаки стеатогепатоза'],
                        ['Неалкогольная жировая болезнь печени', 'Неалкогольная жировая болезнь печени'],
                        ['Цирроз печени', 'Цирроз печени'],
                    ],
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'hernia',
                    placeholder: 'Грыжа',
                    options: [
                        ['Нет', 'Нет'],
                        ['Пупочная', 'Пупочная'],
                        ['Вентральная', 'Вентральная'],
                        ['Паховая', 'Паховая'],
                        ['ГПОД', 'ГПОД'],
                    ],
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'fat-apron',
                    placeholder: 'Жировой фартук',
                    options: [
                        ['Нет', 'Нет'],
                        ['Значительная кожно-жировая складка', 'Значительная кожно-жировая складка'],
                        ['Воспаление в складках', 'Воспаление в складках'],
                        ['Затрудняет ходьбу', 'Затрудняет ходьбу'],
                        ['Целлюлит/изъязвление', 'Целлюлит/изъязвление'],
                    ],
                },
            },
            {
                type: 'RADIO-GROUP',
                data: {
                    placeholder: 'Недержание мочи',
                    name: 'urinary-incontinence-patient',
                    options: [
                        ['Да', 'Да'],
                        ['Нет', 'Нет'],
                    ],
                    required: false,
                },
            },
            {
                type: 'RADIO-GROUP',
                data: {
                    placeholder: 'Депрессия',
                    name: 'depression',
                    options: [
                        ['Да', 'Да'],
                        ['Нет', 'Нет'],
                    ],
                    required: false,
                },
            },
            {
                type: 'RADIO-GROUP',
                data: {
                    placeholder: 'Желчнокаменная болезнь',
                    name: 'cholelithiasis',
                    options: [
                        ['Да', 'Да'],
                        ['Нет', 'Нет'],
                    ],
                    required: false,
                },
            },
        ],
        additionalGroups: [
            {
                name: 'Женское здоровье',
                addClass: 'group--simple',
                connected: 'gender',
                // active: window.PATIENT_DATA.gender === 'Женский' ? true : false,
                content: [
                    {
                        type: 'SELECT',
                        data: {
                            id: 'menstrual-function',
                            name: 'menstrual-function',
                            placeholder: 'Менструальная функция',
                            options: [
                                ['Регулярный цикл', 'Регулярный цикл'],
                                ['Нерегулярный цикл', 'Нерегулярный цикл'],
                                ['Гиперменорея', 'Гиперменорея'],
                                ['Постменопауза', 'Постменопауза'],
                                ['Аменорея', 'Аменорея'],
                                ['Гистерэктомия в анамнезе', 'Гистерэктомия в анамнезе'],
                            ],
                            required: true,
                        },
                    },
                    {
                        type: 'SELECT',
                        data: {
                            id: 'polycystic-ovary',
                            name: 'polycystic-ovary',
                            placeholder: 'Синдром поликистозных яичников',
                            options: [
                                ['Нет', 'Нет'],
                                ['СПКЯ без терапии', 'СПКЯ без терапии'],
                                ['СПКЯ с терапией', 'СПКЯ с терапией'],
                                ['Бесплодие', 'Бесплодие'],
                            ],
                            // required: true,
                        },
                    },
                    {
                        type: 'RADIO-GROUP',
                        data: {
                            placeholder: 'Операция с целью фертильности',
                            name: 'goal-is-fertility',
                            options: [
                                ['Да', 'Да'],
                                ['Нет', 'Нет'],
                            ],
                            required: false,
                        },
                    },
                ],
            },
        ],
    },
    {
        title: 'Анамнез',
        addClass: ['group--simple', 'anamnesis'],
        fields: [
            {
                type: 'SELECT',
                data: {
                    name: 'ASA-scale',
                    placeholder: 'Шкала ASA',
                    options: [
                        ['ASA I (здоровый пациент)', 'ASA I (здоровый пациент)'],
                        ['ASA II (системное заболевание умеренной тяжести)', 'ASA II (системное заболевание умеренной тяжести)'],
                        ['ASA III (тяжелое, но компенсированное либо субкомпенсированное заболевание)', 'ASA III (тяжелое, но компенсированное либо субкомпенсированное заболевание)'],
                        ['ASA IV (тяжелое заболевание, представляющее постоянную угрозу жизни)', 'ASA IV (тяжелое заболевание, представляющее постоянную угрозу жизни)'],
                    ],
                    required: true,
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'smoking',
                    placeholder: 'Курение',
                    options: [
                        ['Никогда не курил / бросил', 'Никогда не курил / бросил'],
                        ['Курит иногда / Редко', 'Курит иногда / Редко'],
                        ['До 20 сигарет в день', 'До 20 сигарет в день'],
                        ['Более 20 сигарет в день', 'Более 20 сигарет в день'],
                    ],
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'PE-risk-factors',
                    placeholder: 'Факторы риска ТЭЛА',
                    options: [
                        ['Нет', 'Нет'],
                        ['Тромбоз глубоких вен или ТЭЛА', 'Тромбоз глубоких вен или ТЭЛА'],
                        ['Венозный отек с изъязвлением', 'Венозный отек с изъязвлением'],
                        ['Кава-фильтр', 'Кава-фильтр'],
                        ['Гиповентиляция', 'Гиповентиляция'],
                    ],
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'regular-medication-intake',
                    placeholder: 'Регулярный прием препаратов',
                    options: [
                        ['От давления', 'От давления'],
                        ['От сахарного диабета 2-го типа', 'От сахарного диабета 2-го типа'],
                        ['НПВС/Обезболивающие', 'НПВС/Обезболивающие'],
                        ['ВИЧ-терапия', 'ВИЧ-терапия'],
                        ['От психо-неврологических заболеваний', 'От психо-неврологических заболеваний'],
                        ['Противозачаточные', 'Противозачаточные'],
                        ['Другое', 'Другое'],
                    ],
                    multiple: true,
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'weight-loss-attempts',
                    placeholder: 'Попытки снижения веса',
                    options: [
                        ['Не было', 'Не было'],
                        ['Диета', 'Диета'],
                        ['Психологические методы', 'Психологические методы'],
                        ['Лечение в клинике питания', 'Лечение в клинике питания'],
                        ['Ксеникал (Орлистат)', 'Ксеникал (Орлистат)'],
                        ['Сибутрамин (Меридиа)', 'Сибутрамин (Меридиа)'],
                        ['Агонисты (ингибиторы) ГПП-1 и ГИП', 'Агонисты (ингибиторы) ГПП-1 и ГИП'],
                        ['Другие препараты', 'Другие препараты'],
                    ],
                    multiple: true,
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'previous-bariatric-surgery',
                    placeholder: 'Предыдущая бариатрическая операция',
                    options: [
                        ['Бандажирование', 'Бандажирование'],
                        ['Внутрижелудочный баллон', 'Внутрижелудочный баллон'],
                        ['Гастропликация', 'Гастропликация'],
                        ['Sleeve (Продольная резекция)', 'Sleeve (Продольная резекция)'],
                        ['MGB-OAGB (Минигастрошунтирование)', 'MGB-OAGB (Минигастрошунтирование)'],
                        ['RYGB (Гастрошунтирование)', 'RYGB (Гастрошунтирование)'],
                        ['BPD (Билиопанкреатическое шунтирование)', 'BPD (Билиопанкреатическое шунтирование)'],
                        ['SADI (Дуоденоилешунтирование)', 'SADI (Дуоденоилешунтирование)'],
                        ['SLIM OAGB (Гастрошунтирование на короткой петле)', 'SLIM OAGB (Гастрошунтирование на короткой петле)'],
                        ['Другая операция', 'Другая операция'],
                    ],
                    multiple: true,
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'history-of-operations',
                    placeholder: 'Операции в Анамнезе',
                    options: [
                        ['Лапароскопия', 'Лапароскопия'],
                        ['Лапаротомия на верхнем этаже брюшной полости', 'Лапаротомия на верхнем этаже брюшной полости'],
                        ['Лапаротомия на нижнем этаже брюшной полости', 'Лапаротомия на нижнем этаже брюшной полости'],
                        ['Множественные лапаротомии', 'Множественные лапаротомии'],
                    ],
                    multiple: true,
                },
            },
            {
                type: 'CHECKBOX',
                data: {
                    name: 'types-operations-in-anamnesis',
                    value: 'Уточнить виды операций в анамнезе',
                    placeholder: 'Уточнить виды операций в анамнезе',
                    hasConnection: 'types-operations-anamnesis',
                },
            },
            {
                type: 'SELECT',
                data: {
                    name: 'types-operations-in-anamnesis-select',
                    placeholder: 'Виды операций в анамнезе',
                    options: [
                        ['Кесарево сечение', 'Кесарево сечение'],
                        ['Гинекологическая операция ', 'Гинекологическая операция '],
                        ['Антирефлюксная операция', 'Антирефлюксная операция'],
                        ['Спленэктомия лапароскопическая ', 'Спленэктомия лапароскопическая'],
                        ['Спленэктомия лапаротомная', 'Спленэктомия лапаротомная'],
                        ['Грыжесечение без импланта', 'Грыжесечение без импланта'],
                        ['Грыжесечение с установкой сетчатого импланта', 'Грыжесечение с установкой сетчатого импланта'],
                        ['Аппендэктомия', 'Аппендэктомия'],
                        ['Холецистэктомия лапароскопическая', 'Холецистэктомия лапароскопическая'],
                        ['Холецистэктомия лапаротомная', 'Холецистэктомия лапаротомная'],
                        ['Открытые вмешательства на сердце', 'Открытые вмешательства на сердце'],
                        ['Рентгенэндоваскулярные операции', 'Рентгенэндоваскулярные операции'],
                        ['Другая (указать какая)', 'Другая (указать какая)'],
                    ],
                    connected: 'types-operations-anamnesis',
                    multiple: true,
                },
            },
        ],
    },
    {
        title: 'Дополнительная информация',
        addClass: ['group--simple', 'patient-additional-info'],
        fields: [
            {
                type: 'TEXTAREA',
                data: {
                    name: 'new-patient-additional',
                    type: 'text',
                    placeholder: 'Примечания',
                    addClass: 'long',
                },
            },
        ],
    },
];

const outdatedData = {
    id: 'outdatedData',
    name: 'Значения из прошлого реестра',
    addClass: ['group--simple'],
    dataBlocks: [],
};

export function createPatientTemplate(patientResponse) {
    const patient = { id: patientResponse.id };
    const patientCopy = structuredClone(patientTemplate);
    // Заполняем поля значениями
    patientCopy.forEach((group) => {
        if (group.fields) {
            group.fields.forEach((field) => {
                const currentField = patientResponse.fields.find((item) => item.name === field.data.name);
                if (currentField) {
                    field.data.value = currentField.value;
                }
                if (currentField && field.type === 'RADIO-GROUP') {
                    field.data.attributes = [{ name: 'data-value', value: currentField.value }];
                }
                if (currentField && field.type === 'CHECKBOX') {
                    field.data.checked = true;
                }
            });
        }
        if (group.additionalGroups) {
            group.additionalGroups.forEach((group) => {
                group.content.forEach((field) => {
                    const currentField = patientResponse.fields.find((item) => item.name === field.data.name);
                    if (currentField) {
                        field.data.value = currentField.value;
                    }
                    if (currentField && field.type === 'RADIO-GROUP') {
                        field.data.attributes = [{ name: 'data-value', value: currentField.value }];
                    }
                    if (currentField && field.type === 'CHECKBOX') {
                        field.data.checked = true;
                    }
                });
            });
        }
    });

    patient.fields = patientCopy;
    if (patientResponse.files) {
        patient.files = patientResponse.files.map((item) => ({ ...item }));
    }

    if (patientResponse.outdatedData.length > 0) {
        outdatedData.dataBlocks = patientResponse.outdatedData;
        patient.outdatedData = outdatedData;
    }

    return patient;
}
